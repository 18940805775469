import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  CounterpartyOutput,
  GetCounterpartyInfoApiCounterpartiesGetApiResponse,
  GetParksApiParksGetApiResponse,
} from '@shared/api/services/common/api';
import { UserBaseInfoRead } from '@shared/api/services/user/api';
import { getTableSettingsColumn } from '@shared/ui/table/settings-column/get-table-settings-column';
import { idTitleColumnUsersMap } from '../../consts';
import { RoleEnum, rolesMap } from '@entities/user/consts/roles-map';
import { EditUserButton } from '@features/users/edit/ui/button';

const columnHelper = createColumnHelper<UserBaseInfoRead>();

export const useColumns = ({
  counterparties,
  counterparty,
  profile,
  parks,
}: {
  profile: UserBaseInfoRead;
  counterparty?: CounterpartyOutput;
  counterparties?: GetCounterpartyInfoApiCounterpartiesGetApiResponse;
  parks: GetParksApiParksGetApiResponse;
}) => {
  const [settings, setSettings] = useState<Record<string, boolean>>({
    username: true,
    last_name: true,
    first_name: true,
    surname: true,
    role_id: true,
    counterparty_id: true,
  });

  const DATA_COLUMNS = [
    columnHelper.accessor('username', {
      id: 'username',
      header: 'Логин',
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: false,
    }),
    columnHelper.accessor('last_name', {
      id: 'last_name',
      header: 'Фамилия',
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: false,
    }),
    columnHelper.accessor('first_name', {
      id: 'first_name',
      header: 'Имя',
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: false,
    }),
    columnHelper.accessor('surname', {
      id: 'surname',
      header: 'Отчество',
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: false,
    }),
    columnHelper.accessor('role_id', {
      id: 'role_id',
      header: 'Роль',
      cell: (props) => rolesMap[RoleEnum[props.getValue() ?? 4]],
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: true,
    }),
    columnHelper.accessor('counterparty_id', {
      id: 'counterparty_id',
      header: 'Контрагент',
      cell: (props) => {
        const counterpartyId = props.getValue();

        if (counterpartyId === undefined || counterpartyId === null) {
          return 'Нет данных';
        }

        if (profile.is_staff) {
          return (
            counterparties?.find(({ id }) => id === counterpartyId)?.name ??
            'Нет данных'
          );
        } else {
          return counterparty?.name ?? 'Нет данных';
        }
      },
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: true,
    }),
  ];

  const settingsColumn = getTableSettingsColumn({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      const typedId = id!;
      return {
        key: id as string,
        label: idTitleColumnUsersMap[typedId],
        isChecked: settings[typedId],
      };
    }),
    settings,
    renderCell: (props) => <EditUserButton user={props.row.original} />,
    setVisibleColumns: (cols: Record<string, boolean>) => {
      setSettings(cols);
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter(({ id }) => {
          const typedId = id!;
          return settings[typedId];
        })
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
