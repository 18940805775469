import { useMemo } from 'react';
import { ElectricBusTableData } from '../consts/types';

type Props = {
  tableData: ElectricBusTableData;
  sortedBy: string | null;
  sortedField: string | null;
};

export const useSortVehicleData = ({
  tableData,
  sortedBy,
  sortedField,
}: Props) => {
  const sortedVehicles = useMemo(() => {
    const clonedTableData = JSON.parse(
      JSON.stringify(tableData)
    ) as ElectricBusTableData;

    if (sortedBy === 'asc' && sortedField !== null) {
      switch (sortedField) {
        case 'grn':
          return clonedTableData.sort((a, b) =>
            a.grn.localeCompare(b.grn, 'ru-RU')
          );
        case 'type_id':
          return clonedTableData.sort((a, b) => {
            const prevName = a.type?.name ?? '';
            const nextName = b.type?.name ?? '';
            return prevName.localeCompare(nextName, 'ru-RU');
          });
        case 'manufacture_id':
          return clonedTableData.sort((a, b) => {
            const prevName = a.manufacturer?.name ?? '';
            const nextName = b.manufacturer?.name ?? '';
            return prevName.localeCompare(nextName, 'ru-RU');
          });
        case 'model_id':
          return clonedTableData.sort((a, b) => {
            const prevName = a.model?.name ?? '';
            const nextName = b.model?.name ?? '';
            return prevName.localeCompare(nextName, 'ru-RU');
          });
        case 'status_id':
          return clonedTableData.sort((a, b) => {
            const prevId = a.status?.id;
            const nextId = b.status?.id;
            if (prevId === undefined || nextId === undefined) {
              return 0;
            }
            return prevId - nextId;
          });
        default:
          return clonedTableData.sort(
            // @ts-ignore
            (a, b) => a[sortedField] - b[sortedField]
          );
      }
    }

    if (sortedBy === 'desc' && sortedField !== null) {
      switch (sortedField) {
        case 'grn':
          return clonedTableData.sort((a, b) =>
            b.grn.localeCompare(a.grn, 'ru-RU')
          );
        case 'type_id':
          return clonedTableData.sort((a, b) => {
            const prevName = a.type?.name ?? '';
            const nextName = b.type?.name ?? '';
            return nextName.localeCompare(prevName, 'ru-RU');
          });
        case 'manufacture_id':
          return clonedTableData.sort((a, b) => {
            const prevName = a.manufacturer?.name ?? '';
            const nextName = b.manufacturer?.name ?? '';
            return nextName.localeCompare(prevName, 'ru-RU');
          });
        case 'model_id':
          return clonedTableData.sort((a, b) => {
            const prevName = a.model?.name ?? '';
            const nextName = b.model?.name ?? '';
            return nextName.localeCompare(prevName, 'ru-RU');
          });
        case 'status_id':
          return clonedTableData.sort((a, b) => {
            const prevId = a.status?.id;
            const nextId = b.status?.id;
            if (prevId === undefined || nextId === undefined) {
              return 0;
            }
            return nextId - prevId;
          });
        default:
          return clonedTableData.sort(
            // @ts-ignore
            (a, b) => b[sortedField] - a[sortedField]
          );
      }
    }

    return clonedTableData;
  }, [tableData, sortedField, sortedBy]);

  return sortedVehicles;
};
