import { Flex } from 'antd';
import { EditProfileButtonWrapper } from './styles';
import { ArrowRightIcon } from '@shared/icons/arrow-right';
import { UserBaseInfoRead } from '@shared/api/services/user/api';
import NiceModal from '@ebay/nice-modal-react';
import { EditUserModal } from '../modal';
import { FC } from 'react';

type Props = {
  data: UserBaseInfoRead;
};

export const EditProfileButton: FC<Props> = ({ data }) => {
  const showModal = () => {
    NiceModal.show(EditUserModal, { user: data, isProfile: true });
  };

  return (
    <EditProfileButtonWrapper>
      <Flex onClick={() => showModal()} justify="space-between" align="center">
        Редактировать профиль
        <ArrowRightIcon />
      </Flex>
    </EditProfileButtonWrapper>
  );
};
