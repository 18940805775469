import { Divider, Flex } from 'antd';

import { ArrowRightIcon } from '@shared/icons/arrow-right';
import { LogoutButton } from '@features/auth/logout';

import { ToggleThemeSwitch } from '@features/toggle-theme/ui';

import { StyledContainer, UserSettingsTitle } from './styles';
import NiceModal from '@ebay/nice-modal-react';
import { FC } from 'react';
import { UserBaseInfoRead } from '@shared/api/services/user/api';
import { EditProfileButton } from '@features/users/edit/ui/edit-profile-button';

type Props = {
  data: UserBaseInfoRead;
};

export const UserSettings: FC<Props> = (props) => {
  return (
    <StyledContainer>
      <UserSettingsTitle>Настройки</UserSettingsTitle>
      <EditProfileButton {...props} />
      <Divider
        style={{ margin: '20px 0', borderColor: '#F7FAFF', opacity: 0.5 }}
      />
      <Flex justify="space-between" align="center">
        <ToggleThemeSwitch />
        <LogoutButton />
      </Flex>
    </StyledContainer>
  );
};
