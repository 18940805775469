import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import {
  ApiUsersListListApiResponse,
  UserBaseInfoRead,
} from '@shared/api/services/user/api';
import { ServerTableLayout } from '@shared/ui/table/layout';
import {
  CounterpartyOutput,
  GetCounterpartyInfoApiCounterpartiesGetApiResponse,
  GetParksApiParksGetApiResponse,
} from '@shared/api/services/common/api';
import { usePaginationState, useSortingState } from '@shared/ui/table/hooks';
import { QUERY_PARAM } from '@shared/consts/server';

import { useColumns } from '../../hooks/use-columns';

type Props = {
  users: ApiUsersListListApiResponse;
  counterparties: GetCounterpartyInfoApiCounterpartiesGetApiResponse;
  counterparty?: CounterpartyOutput;
  profile: UserBaseInfoRead;
  parks: GetParksApiParksGetApiResponse;
  count: number;
  defaultPageSize: number;
  loading: boolean;
};

export const UsersTableInstance: FC<Props> = ({
  users,
  counterparties,
  counterparty,
  profile,
  parks,
  loading,
  defaultPageSize,
  count,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const columns = useColumns({ profile, counterparty, parks, counterparties });
  const sorting = useSortingState();
  const pagination = usePaginationState();

  const size = searchParams.get(QUERY_PARAM.limit);
  const page = searchParams.get(QUERY_PARAM.page);
  const sortedField = searchParams.get(QUERY_PARAM.ordering);
  const sortedBy = searchParams.get(QUERY_PARAM.type);

  const sortedData = useMemo(() => {
    const clonedBatteriesData = JSON.parse(
      JSON.stringify(users)
    ) as ApiUsersListListApiResponse;

    if (sortedBy === 'asc' && sortedField !== null) {
      return clonedBatteriesData.sort(
        // @ts-ignore
        (a, b) => a[sortedField] - b[sortedField]
      );
    }

    if (sortedBy === 'desc' && sortedField !== null) {
      return clonedBatteriesData.sort(
        // @ts-ignore
        (a, b) => b[sortedField] - a[sortedField]
      );
    }

    return clonedBatteriesData;
  }, [users, sortedField, sortedBy]);

  const paginatedData = useMemo(() => {
    const currentPage = Number(page ?? 1);
    const currentSize = Number(size ?? defaultPageSize);

    const startIdx = (currentPage - 1) * currentSize;
    const endIdx = startIdx + currentSize;
    return sortedData.slice(startIdx, endIdx);
  }, [sortedData, size, page]);

  const table = useReactTable({
    data: paginatedData,
    columns,
    state: {
      sorting,
      pagination,
    },
    getRowId: (row) => row.username,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (size ? Number(size) : defaultPageSize))
      : undefined,
  });

  return (
    <ServerTableLayout
      loading={loading}
      table={table}
      rowsCount={count}
      defaultPageSize={defaultPageSize}
    />
  );
};
