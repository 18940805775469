import styled from 'styled-components';

export const BatteryErrorsPanelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ErrorsList = styled.div<{ isSingleChange: boolean }>`
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-left: ${({ isSingleChange }) => (isSingleChange ? '19px' : '5px')};
`;

export const IconWrapper = styled.div`
  width: 20px;
  align-self: center;
`;
