import { enhancedApi as api } from '../api';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    apiAuthTokenAuthCreate: (endpoint) => {
      endpoint.query = (queryArg) => ({
        url: `/api/auth/token-auth/`,
        method: 'POST',
        body: queryArg,
      });
      endpoint.invalidatesTags = ['Profile', 'Electricbuses', 'VehicleGroups'];
    },
    //      query: () => ({ url: `/api/users/self` }),

    apiUsersSelfRetrieve: (endpoint) => {
      endpoint.query = () => `/api/users/self`;
      endpoint.providesTags = ['Profile'];
    },
    apiUsersListList: (endpoint) => {
      (endpoint.query = () => `/api/users/list/`),
        (endpoint.providesTags = ['Users']);
    },
    apiUsersCreateCreate: (endpoint) => {
      endpoint.query = (body) => ({
        url: `/api/users/create/`,
        method: 'POST',
        body,
      });
      endpoint.invalidatesTags = ['Users'];
    },
    apiUsersUpdatePartialUpdate: (endpoint) => {
      endpoint.query = (queryArg) => ({
        url: `/api/users/${queryArg.username}/update/`,
        method: 'PATCH',
        body: queryArg.patchedUserUpdate,
      });
      endpoint.invalidatesTags = ['Users', 'Profile'];
    },
    apiUsersSelfPartialUpdate: (endpoint) => {
      endpoint.query = (queryArg) => ({
        url: `/api/users/self`,
        method: 'PATCH',
        body: queryArg,
      });
      endpoint.invalidatesTags = ['Users', 'Profile'];
    },
    apiUsersDeleteDestroy: (endpoint) => {
      endpoint.query = (queryArg) => ({
        url: `/api/users/${queryArg}/delete/`,
        method: 'DELETE',
      });
      endpoint.invalidatesTags = ['Users'];
    },
  },
});

export const {
  useApiAuthTokenAuthCreateMutation: useLoginMutation,
  useApiUsersSelfRetrieveQuery: useGetUsersInfoQuery,
  useApiUsersSelfPartialUpdateMutation: useUpdateProfileMutation,
  useApiUsersListListQuery: useGerUsersListQuery,
  useApiUsersCreateCreateMutation: useCreateUserMutation,
  useApiUsersUpdatePartialUpdateMutation: useUpdateUserMutation,
  useApiUsersDeleteDestroyMutation: useDeleteUserMutation,
} = enhancedApi;
