import { z } from 'zod';

export const CreatingSchema = z.object({
  username: z
    .string({
      required_error: 'Поле не должно быть пустым',
    })
    .min(1, { message: 'Поле не должно быть пустым' }),
  password: z
    .string({
      required_error: 'Поле не должно быть пустым',
    })
    .min(1, { message: 'Поле не должно быть пустым' }),
  first_name: z
    .string({
      required_error: 'Поле не должно быть пустым',
    })
    .min(1, { message: 'Поле не должно быть пустым' }),
  last_name: z
    .string({
      required_error: 'Поле не должно быть пустым',
    })
    .min(1, { message: 'Поле не должно быть пустым' }),
  surname: z.string().optional(),
  available_park_ids: z
    .array(z.number(), { message: 'Выберите хотя бы один парк' })
    .nonempty('Выберите хотя бы один парк'),
  role_id: z.number({
    required_error: 'Поле не должно быть пустым',
  }),
  counterparty_id: z.number({
    required_error: 'Поле не должно быть пустым',
  }),
  is_staff: z.boolean().optional(),
});

export const EditingSchema = z.object({
  username: z.string().min(1),
  password: z.string().optional(),
  first_name: z
    .string()
    .refine((val) => val.trim().length > 0, {
      message: 'Поле не должно быть пустым',
    })
    .optional(),
  last_name: z.string().refine((val) => val.trim().length > 0, {
    message: 'Поле не должно быть пустым',
  }),
  surname: z.string().optional(),
  available_park_ids: z.array(z.number()).optional(),
  role_id: z.number().optional(),
  counterparty_id: z.number().optional(),
  is_staff: z.boolean().optional(),
});

export type CreatingFormSchemaType = z.infer<typeof CreatingSchema>;
export type EditingFormSchemaType = z.infer<typeof EditingSchema>;
