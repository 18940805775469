import { FC } from 'react';
import { BatteryTelemetryRedisModel } from '@shared/api/services/telemetry/api';
import { Tooltip } from 'antd';

import {
  BatteriesMarkersWrapper,
  BatteryMarker,
  TooltipWrapper,
  BatteryNumber,
  BatteryInfoWrapper,
  VoltageBlock,
  TemperatureBlock,
  BlockTitle,
  InfoField,
} from './styles';

type Props = {
  batteries: BatteryTelemetryRedisModel[];
};

const BatteryDataTooltip: FC<{ battery: BatteryTelemetryRedisModel }> = ({
  battery,
}) => {
  const {
    number_battery,
    min_voltage_V,
    min_temperature_C,
    avg_voltage_V,
    coolant_temp_C,
    max_voltage_V,
    max_temperature_C,
  } = battery;
  return (
    <TooltipWrapper>
      <BatteryNumber>Батарея {number_battery}</BatteryNumber>
      <BatteryInfoWrapper>
        <VoltageBlock>
          <BlockTitle>Напряжение</BlockTitle>
          <InfoField>Мин.: {min_voltage_V}</InfoField>
          <InfoField>Сред.: {avg_voltage_V}</InfoField>
          <InfoField>Макс.: {max_voltage_V}</InfoField>
        </VoltageBlock>
        <TemperatureBlock>
          <BlockTitle>Температура</BlockTitle>
          <InfoField>Мин.: {min_temperature_C}</InfoField>
          <InfoField>Темп. охлажд. ТАБ: {coolant_temp_C}</InfoField>
          <InfoField>Макс.: {max_temperature_C}</InfoField>
        </TemperatureBlock>
      </BatteryInfoWrapper>
    </TooltipWrapper>
  );
};

export const BatteryMarkersPanel: FC<Props> = ({ batteries }) => {
  const clonedBatteries = JSON.parse(
    JSON.stringify(batteries)
  ) as BatteryTelemetryRedisModel[];

  const batteriesMarkers = clonedBatteries
    .sort((a, b) => a.number_battery - b.number_battery)
    .map((battery, index) => {
      return (
        <Tooltip
          key={battery.number_battery}
          title={<BatteryDataTooltip battery={battery} />}
          fresh={true}
          overlayInnerStyle={{
            width: '270px',
          }}
        >
          <BatteryMarker key={battery.number_battery} isFirst={index === 0} />
        </Tooltip>
      );
    });

  return <BatteriesMarkersWrapper>{batteriesMarkers}</BatteriesMarkersWrapper>;
};
