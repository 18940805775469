import { FC, useState } from 'react';
import { UpCircleTwoTone, DownCircleTwoTone } from '@ant-design/icons';

import { BatteryTelemetryErrorRedisModel } from '@shared/api/services/telemetry/api';
import { BatteryErrorsPanelWrapper, ErrorsList, IconWrapper } from './styles';

type Props = {
  errors: BatteryTelemetryErrorRedisModel[];
};

export const BatteryErrorsPanel: FC<Props> = ({ errors }) => {
  const [isExpand, toggleExpand] = useState(false);
  const isSingle = errors.length === 1;

  const result = errors.map(({ code_error, text_error }, id) => {
    return (
      <div key={`${id}-${code_error}-${text_error}`}>
        {code_error}: {text_error}
      </div>
    );
  });

  return (
    <BatteryErrorsPanelWrapper>
      {isSingle ? null : isExpand ? (
        <IconWrapper>
          <UpCircleTwoTone width={20} onClick={() => toggleExpand(false)} />
        </IconWrapper>
      ) : (
        <IconWrapper>
          <DownCircleTwoTone width={20} onClick={() => toggleExpand(true)} />
        </IconWrapper>
      )}
      <ErrorsList isSingleChange={isSingle}>
        {isExpand ? result : result[0]}
      </ErrorsList>
    </BatteryErrorsPanelWrapper>
  );
};
