import styled from 'styled-components';
import { Radio, RadioChangeEvent } from 'antd';

export const Outer = styled.div`
  position: sticky;
  top: 0;
  margin-bottom: 15px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const ChargingStationsTitle = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

export const TelemetryTableTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TelemetryTableTypeBlockTitle = styled.p`
  font-weight: 600;
  font-size: 17px;
`;
