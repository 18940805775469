import { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useElectricbusesData } from '@entities/vehicle/hooks/use-electricbuses-data';
import {
  selectVehicleFilters,
  setFilters,
} from '@shared/redux/slices/vehicle/filters';

import { useAppSelector } from '@app/hooks';

import { VehiclesListTableInstance } from '../table';
import { useDispatch } from 'react-redux';
import { preprocessGroupIdsArg } from '@entities/vehicle/lib/preprocess-group-ids-arg';
import { countPageSize } from '@shared/ui/table/lib/count-page-size';
import { useFilterData } from '../../../../../entities/vehicle/hooks/use-filter-data';
import { VehicleFilterType } from '../../consts/types';

type Props = {
  tableType: 'TRANSPORT' | 'PARK' | 'CONVOY';
};

export const ConnectedVehiclesTable: FC<Props> = ({ tableType }) => {
  const filters = useAppSelector(selectVehicleFilters) as VehicleFilterType;

  const { groupIds, parkId } = filters;

  const { tableData, vehicleStatuses, parks, isLoading, isSuccess, error } =
    useElectricbusesData({ eParkId: parkId ? [parkId] : undefined, groupIds });

  const filteredData = useFilterData({ tableType, parks, tableData, filters });

  const defaultPageSize = countPageSize();

  if (isLoading) {
    return <div>Идет загрузка....</div>;
  }

  if (error) {
    return <div>Ошибка</div>;
  }

  if (!tableData) {
    return <div>Нет данных</div>;
  }

  return (
    <VehiclesListTableInstance
      defaultPageSize={defaultPageSize}
      loading={isLoading}
      count={tableData.length}
      tableData={filteredData}
      statuses={vehicleStatuses}
      parks={parks}
      tableType={tableType}
    />
  );
};
