export enum RoleEnum {
  'OPERATOR' = 1,
  'DISPATCHER' = 2,
  'ADMIN' = 3,
  'UNINDENTIFIED' = 4,
}

export const rolesMap = {
  [RoleEnum[1]]: 'Оператор',
  [RoleEnum[2]]: 'Диспетчер',
  [RoleEnum[3]]: 'Администратор',
  [RoleEnum[4]]: 'Неопределенная роль',
};
