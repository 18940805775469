import { useState, useEffect } from 'react';
import { useMatch, useResolvedPath } from 'react-router-dom';

import {
  SETTINGS,
  TRACKERS,
  TELEMETRY,
  CHARGING_STATIONS,
  COUNTERPARTIES,
  USERS,
} from '@shared/consts/routes';
import { ArrowDownIcon } from '@shared/icons/arrow-down';

import { SiderNavMenuItemLayout } from '../item-layout-new';
import { StyledCollapse } from '../styles';
import { SettingsMenuContainer } from './styles';
import { useGetUsersInfoQuery } from '@shared/api/services/user/enhanced';

export function SettingsMenu() {
  const [activeKey, setActiveKey] = useState<string | string[]>([]);
  const { data, isLoading, isSuccess, error } = useGetUsersInfoQuery();

  const isAdmin = data?.role_id === 3;

  const resolvedPath = useResolvedPath(SETTINGS);

  const match = useMatch({
    path: resolvedPath.pathname,
    end: SETTINGS.length === 0,
  });

  const className = match ? 'active' : '';

  useEffect(() => {
    if (!match) {
      setActiveKey([]);
    }
  }, [match]);

  return (
    <SiderNavMenuItemLayout
      path={SETTINGS}
      content={
        <StyledCollapse
          activeKey={activeKey}
          ghost
          rootClassName={''}
          collapsible={'header'}
          onChange={(key) => {
            setActiveKey(key);
          }}
          items={[
            {
              id: 'settings-menu',
              label: 'Техническая информация',
              children: (
                <SettingsMenuContainer>
                  <SiderNavMenuItemLayout
                    path={TRACKERS}
                    content={<div>Трекеры</div>}
                  />
                  <SiderNavMenuItemLayout
                    path={TELEMETRY}
                    content={<div>Телеметрия</div>}
                  />
                  <SiderNavMenuItemLayout
                    path={CHARGING_STATIONS}
                    content={<div>Зарядные станции</div>}
                  />
                  <SiderNavMenuItemLayout
                    path={COUNTERPARTIES}
                    content={<div>Контрагенты</div>}
                  />
                  {isAdmin && (
                    <SiderNavMenuItemLayout
                      path={USERS}
                      content={<div>Пользователи</div>}
                    />
                  )}
                </SettingsMenuContainer>
              ),
            },
          ]}
          expandIconPosition="end"
          expandIcon={(_panelProps) => {
            return <ArrowDownIcon />;
          }}
          className={className}
        />
      }
    />
  );
}
