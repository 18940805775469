import { enhancedApi as api } from '../api';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getElectricbusesApiElectricbusesGet: (endpoint) => {
      (endpoint.query = (queryArg) => {
        return {
          url: `electricbus/api/electricbuses`,
          params: {
            group_ids: queryArg,
          },
        };
      }),
        (endpoint.providesTags = ['Electricbuses']);
    },
    createElectricbusApiElectricbusesPost: (endpoint) => {
      endpoint.query = (body) => ({
        method: 'POST',
        url: `electricbus/api/electricbuses`,
        body,
      });
      // endpoint.invalidatesTags = (result, error) => error ? [] : ['Electricbuses'],
      endpoint.invalidatesTags = ['Electricbuses'];
    },
    updateElectricbusApiElectricbusesPatch: (endpoint) => {
      (endpoint.query = (body) => ({
        method: 'PATCH',
        url: `electricbus/api/electricbuses`,
        body,
      })),
        // (endpoint.invalidatesTags = (result, error) => error ? [] : ['Electricbuses']),
        (endpoint.invalidatesTags = ['Electricbuses']);
    },
    deleteElectricbusApiElectricbusesEvIdDelete: (endpoint) => {
      (endpoint.query = (queryArg) => ({
        method: 'DELETE',
        url: `electricbus/api/electricbuses/${queryArg}`,
      })),
        // (endpoint.invalidatesTags = (result, error) => error ? [] : ['Electricbuses']),
        (endpoint.invalidatesTags = ['Electricbuses']);
    },
  },
});

export const {
  useGetElectricbusesApiElectricbusesGetQuery: useGetElectricbusesQuery,
  useLazyGetElectricbusesApiElectricbusesGetQuery: useLazyElectricbusesQuery,
  useCreateElectricbusApiElectricbusesPostMutation: useAddElectricbusMutation,
  useUpdateElectricbusApiElectricbusesPatchMutation:
    useUpdateElectricbusMutation,
  useDeleteElectricbusApiElectricbusesEvIdDeleteMutation:
    useDeleteElectricbusMutation,
} = enhancedApi;
