import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';

const dayjsType = z.custom<Dayjs>((data) => dayjs.isDayjs(data), {
  message: 'Expected an instance of dayjs',
});

export const DownloadArchiveFormSchema = z.object({
  range: z
    .array(dayjsType, {
      required_error: 'Поле не должно быть пустым',
    })
    .length(2, { message: 'Нужно ввести две даты' }),
  imei: z.string({
    required_error: 'Введите id трекера',
  }),
});

export type DownloadArchiveFormSchemaType = z.infer<
  typeof DownloadArchiveFormSchema
>;
