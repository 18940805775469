import { FC } from 'react';
import { UserBaseInfoRead } from '@shared/api/services/user/api';
import {
  useUpdateProfileMutation,
  useUpdateUserMutation,
} from '@shared/api/services/user/enhanced';
import { ConnectedUserForm } from '@entities/user/ui/connected-user-form';
import { DeleteUserButton } from '@features/users/delete/ui/button';
import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';
import { EDIT_ERROR, EDIT_SUCCESS } from '../../const';
import { isErrorWithMessage } from '@shared/lib/http/is-error-with-message';
import { EditingFormSchemaType } from '@entities/user/consts/schemas';

type Props = {
  user: UserBaseInfoRead;
  isProfile: boolean;
  closeModal: () => void;
};

export const EditUser: FC<Props> = ({ user, isProfile, closeModal }) => {
  const [update, { isLoading, isSuccess, error }] = useUpdateUserMutation();
  const [updateProfile, { isLoading: isUpdateProfileLoading }] =
    useUpdateProfileMutation();

  const handleSubmit = async (data: EditingFormSchemaType) => {
    let res;

    try {
      if (isProfile) {
        res = await updateProfile(data);
      } else {
        res = await update({
          username: user.username,
          patchedUserUpdate: data,
        });
      }

      openSuccessNotification(`${EDIT_SUCCESS}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err?.data.detail : EDIT_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  return (
    <ConnectedUserForm
      formType="editing"
      //  submitButtonLabel="Сохранить"
      isProfile={isProfile}
      handleFormSubmit={handleSubmit}
      loading={isLoading}
      user={user}
      deleteButton={
        <DeleteUserButton user={user} closeParentModal={closeModal} />
      }
    />
  );
};
