import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { DownloadOutlined } from '@ant-design/icons';
import { BatteryTelemetryData } from '../consts/types';
import { getTableSettingsColumn } from '@shared/ui/table/settings-column/get-table-settings-column';
import { idTitleColumnBatteryTelMap } from '../consts/id-title-column-map';
import { BatteryErrorsPanel } from '@entities/battery/ui/battery-errors-panel';

const columnHelper = createColumnHelper<BatteryTelemetryData>();

export const useBatteryTelemetryColumns = () => {
  const [settings, setSettings] = useState<Record<string, boolean>>({
    imei: true,
    grz: true,
    garage_number: true,
    number_battery: true,
    min_voltage_V: true,
    avg_voltage_V: true,
    max_voltage_V: true,
    min_temperature_C: true,
    max_temperature_C: true,
    coolant_temp_C: true,
    battery_main_contactor: true,
    firmware_bms: true,
    errors: true,
  });

  const DATA_COLUMNS = [
    columnHelper.accessor('imei', {
      id: 'imei',
      header: 'ID трекера',
      cell: (props) => {
        return props.getValue();
      },
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: true,
    }),
    columnHelper.accessor('grz', {
      id: 'grz',
      header: 'ГРЗ',
      cell: (props) => {
        return props.getValue();
      },
      size: 130,
      minSize: 130,
      maxSize: 130,
      enableSorting: false,
    }),
    columnHelper.accessor('garage_number', {
      id: 'garage_number',
      header: (
        <>
          <p>Гаражный</p>
          <p>номер</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 130,
      minSize: 130,
      maxSize: 130,
      enableSorting: true,
    }),
    columnHelper.accessor('number_battery', {
      id: 'number_battery',
      header: (
        <>
          <p>Номер</p>
          <p>батареи</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 120,
      minSize: 120,
      maxSize: 120,
      enableSorting: true,
    }),
    columnHelper.accessor('min_voltage_V', {
      id: 'min_voltage_V',
      header: (
        <>
          <p>Минимальное</p>
          <p>напряжение</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 160,
      minSize: 160,
      maxSize: 160,
      enableSorting: true,
    }),
    columnHelper.accessor('avg_voltage_V', {
      id: 'avg_voltage_V',
      header: (
        <>
          <p>Среднее</p>
          <p>напряжение</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 160,
      minSize: 160,
      maxSize: 160,
      enableSorting: true,
    }),
    columnHelper.accessor('max_voltage_V', {
      id: 'max_voltage_V',
      header: (
        <>
          <p>Максимальное</p>
          <p>напряжение</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 160,
      minSize: 160,
      maxSize: 160,
      enableSorting: true,
    }),
    columnHelper.accessor('min_temperature_C', {
      id: 'min_temperature_C',
      header: (
        <>
          <p>Минимальная</p>
          <p>температура</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 160,
      minSize: 160,
      maxSize: 160,
      enableSorting: true,
    }),
    columnHelper.accessor('max_temperature_C', {
      id: 'max_temperature_C',
      header: (
        <>
          <p>Максимальная</p>
          <p>температура</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 160,
      minSize: 160,
      maxSize: 160,
      enableSorting: true,
    }),
    columnHelper.accessor('coolant_temp_C', {
      id: 'coolant_temp_C',
      header: (
        <>
          <p>Температура</p>
          <p>охлаждающей</p>
          <p>жидкости</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 160,
      minSize: 160,
      maxSize: 160,
      enableSorting: true,
    }),
    columnHelper.accessor('battery_main_contactor', {
      id: 'battery_main_contactor',
      header: (
        <>
          <p>Состояние</p>
          <p>контакторов</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 150,
      minSize: 150,
      maxSize: 150,
      enableSorting: true,
    }),
    columnHelper.accessor('firmware_bms', {
      id: 'firmware_bms',
      header: (
        <>
          <p>Прошивка</p>
          <p>BMS</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 130,
      minSize: 130,
      maxSize: 130,
      enableSorting: true,
    }),
    columnHelper.accessor('errors', {
      id: 'errors',
      header: 'Ошибки',
      cell: (props) => {
        const value = props.getValue();
        if (value === undefined || value === null) return 'Нет данных';
        if (value.length === 0) return 'Ошибок нет';
        return <BatteryErrorsPanel errors={value} />;
      },
      size: 400,
      minSize: 400,
      maxSize: 400,
      enableSorting: false,
    }),
  ];

  const settingsColumn = getTableSettingsColumn({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      const typedId = id!;
      return {
        key: typedId,
        label: idTitleColumnBatteryTelMap[typedId],
        isChecked: settings[typedId],
      };
    }),
    settings,
    renderCell: (props) => {
      return null;
    },
    setVisibleColumns: (cols: Record<string, boolean>) => {
      setSettings(cols);
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter(({ id }) => {
          const typedId = id!;
          return settings[typedId];
        })
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
