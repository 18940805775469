import { DownloadOutlined } from '@ant-design/icons';

import NiceModal from '@ebay/nice-modal-react';
import { StyledButton } from './styled';
import { FC } from 'react';
import { DownloadArchiveModal } from '../modal';

type Props = {
  imei: string;
};

export const DownloadButton: FC<Props> = ({ imei }) => {
  const showModal = () => {
    NiceModal.show(DownloadArchiveModal, { imei });
  };

  return (
    <StyledButton onClick={showModal}>
      <DownloadOutlined />
    </StyledButton>
  );
};
