import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';

import {
  selectVehicleFilters,
  setFilters,
} from '@shared/redux/slices/vehicle/filters';

import { useGetHotStorageTelemetryQuery } from '@shared/api/services/telemetry/enhanced';
import { Telemetry } from '@entities/vehicle/model/telemetry';

import { useAppSelector } from '@app/hooks';
import { useDispatch } from 'react-redux';
import { VehicleTableType } from '@entities/vehicle/model/vehicle-table-type';
import { preprocessGroupIdsArg } from '@entities/vehicle/lib/preprocess-group-ids-arg';
import { StandardPageLayout } from '@pages/styles';
import { ElectrobusExpandedInfoPageHeader } from '@widgets/header/ui/electrobus-expanded-info-page';
import { VehicleInfoExpanded } from '@widgets/vehicle/vehicle-info-expanded';
import { TRANSPORT } from '@shared/consts/routes';
import { getWebSocketURL } from '@shared/lib/web-socket/get-web-socket-url';
import { selectAccessToken } from '@shared/redux/slices/auth/storage-slice';
import { useGetUsersInfoQuery } from '@shared/api/services/user/enhanced';
import { useElectricbusesData } from '@entities/vehicle/hooks/use-electricbuses-data';
import { useFilterData } from '@entities/vehicle/hooks/use-filter-data';
import { VehicleFilterType } from '@features/vehicle/vehicles-table/consts/types';

const SOCKET_URL = getWebSocketURL();

export function ElectrobusInfoPage() {
  const accessToken = useAppSelector(selectAccessToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mapType, setMapType] = useState<VehicleTableType | null>(null);
  const [telemetry, setTelemetry] = useState<Record<string, Telemetry>>({});

  const { eParkId, convoyId, eBusId } = useParams();

  const filters = useAppSelector(selectVehicleFilters) as VehicleFilterType;

  const { groupIds } = filters;

  const typedParkId = Number(eParkId!);

  const {
    parks,
    tableData,
    isLoading: isParksAndVehiclesDataLoading,
  } = useElectricbusesData({ groupIds, eParkId: [typedParkId] });

  const electricbuses = useFilterData({
    tableType: mapType!,
    parks,
    tableData,
    filters,
  });

  // Добавить уведомление об обсутствии данных в редисе
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isSuccess: isUserDataSuccess,
    error: userDataError,
  } = useGetUsersInfoQuery();

  const { data, isLoading, isFetching, error } = useGetHotStorageTelemetryQuery(
    eParkId!
  );

  useEffect(() => {
    if (eParkId && convoyId && eBusId) {
      setMapType('ELECTROBUS');
      return;
    }
    if (eParkId && convoyId && !eBusId) {
      setMapType('CONVOY');
      return;
    }
    if (eParkId && !convoyId && !eBusId) {
      setMapType('PARK');
      return;
    }
  }, [eParkId, convoyId, eBusId]);

  useEffect(() => {
    if (mapType) {
      dispatch(
        setFilters({
          ...filters,
          groupIds: preprocessGroupIdsArg(mapType, eParkId, convoyId, parks),
          parkId: Number(eParkId),
        })
      );
    }
  }, [mapType, eParkId, convoyId, eBusId]);

  const { lastMessage, lastJsonMessage, readyState, sendJsonMessage } =
    useWebSocket(SOCKET_URL);

  const handleSubscribe = useCallback(() => {
    if (userData?.username !== undefined) {
      sendJsonMessage({
        type: 'subscription',
        payload: {
          username: userData?.username,
          park_id: eParkId,
          token: accessToken,
        },
      });
    }
  }, [groupIds, userData?.username]);

  useEffect(() => {
    if (userData?.username !== undefined) {
      sendJsonMessage({
        type: 'subscription',
        payload: {
          username: userData.username,
          park_id: eParkId,
          token: accessToken,
        },
      });
    }
  }, [eParkId, groupIds, userData?.username]);

  useEffect(() => {
    if (data) {
      const latestTelemetry = data.reduce((acc, el) => {
        return { ...acc, [el.tracker_imei]: el };
      }, {} as Record<string, Telemetry>);

      setTelemetry(latestTelemetry);
    }
  }, [data]);

  useEffect(() => {
    if (readyState === 1 && data && userData?.username) {
      handleSubscribe();
    }
  }, [readyState, userData?.username]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const message = lastJsonMessage as Telemetry;

      if (
        message.tracker_imei &&
        data?.some(({ tracker_imei }) => tracker_imei === message.tracker_imei)
      ) {
        setTelemetry((prev) => {
          return { ...prev, [message.tracker_imei]: message };
        });
      }
    }
  }, [lastMessage, data]);

  const [selectedTrackerImei, setSelectedTrackerImei] = useState('');

  const isStillLoading = isLoading || isParksAndVehiclesDataLoading;

  useEffect(() => {
    if (selectedTrackerImei.length > 0) {
      navigate(`${TRANSPORT}/${eParkId}/${convoyId}/${eBusId}/details`);
    }
  }, [selectedTrackerImei]);

  const [currentEbus] = electricbuses; // пробросить в VehicleInfoExpanded, для отображения нетелеметрических данных по электробусу
  const trackerImei = currentEbus?.imei;

  return (
    <StandardPageLayout>
      <ElectrobusExpandedInfoPageHeader />
      {isStillLoading && <>Загрузка данных</>}
      {trackerImei && !isStillLoading && (
        <VehicleInfoExpanded trackerTelemetry={telemetry[trackerImei]} />
      )}
      {!trackerImei && !isStillLoading && (
        <div
          style={{
            marginTop: '75px',
            border: '1px solid grey',
            width: '50%',
            padding: '20px',
          }}
        >
          У транспорта отсутствует трекер
        </div>
      )}
    </StandardPageLayout>
  );
}
