import { useAppSelector } from '@app/hooks';
import {
  SortDirection,
  TrackerSortEnum,
} from '@shared/api/services/tracker/api';
import {
  useGetTrackersQuery,
  useLazyGetTrackersQuery,
} from '@shared/api/services/tracker/enhanced';
import { QUERY_PARAM } from '@shared/consts/server';
import { selectTrackersFilters } from '@shared/redux/slices/tracker/filters';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TrackersTableInstance } from '../table';
import { useGetTrackersModelsQuery } from '@shared/api/services/common/enhanced';
import { RowSelectionState } from '@tanstack/react-table';
import { countPageSize } from '@shared/ui/table/lib/count-page-size';

type Props = {
  rowSelection: RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
};

export const ConnectedTrackersTable: FC<Props> = ({
  rowSelection,
  setRowSelection,
}) => {
  const [isPaginatingOrFiltration, setIsFiltratingOrPagination] =
    useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const { statuses, firmware, modelIds } = useAppSelector(
    selectTrackersFilters
  );

  const page = searchParams.get(QUERY_PARAM.page);
  const size = searchParams.get(QUERY_PARAM.limit);
  const ordering = searchParams.get(
    QUERY_PARAM.ordering
  ) as TrackerSortEnum | null;
  const type = searchParams.get(QUERY_PARAM.type) as SortDirection | null;

  const {
    data: trackersModels,
    error: trackersModelsError,
    isLoading: areTrackersModelsLoading,
    isSuccess: areTrackersModelsSuccess,
  } = useGetTrackersModelsQuery();

  const defaultPageSize = countPageSize();

  const [
    trackersTrigger,
    { data: trackersOutput, isLoading, isFetching, isSuccess, error },
  ] = useLazyGetTrackersQuery({ pollingInterval: 5000 });

  useEffect(() => {
    setIsFiltratingOrPagination(true);
    trackersTrigger({
      imeiLike: undefined, // здесь фильтрации по imei у нас нет пока что
      modelIds: modelIds.length !== 0 ? modelIds.join(',') : undefined,
      statuses: statuses.length !== 0 ? statuses.join(',') : undefined,
      firmware: firmware !== undefined ? firmware : undefined,
      sortedField: ordering ?? undefined,
      sortedBy: type ?? undefined,
      page: page ? Number(page) : undefined,
      size: size ? Number(size) : defaultPageSize,
    })
      .unwrap()
      .finally(() => {
        setIsFiltratingOrPagination(false);
      });
  }, [modelIds, statuses, firmware, ordering, type, page, size]);

  const tableData = useMemo(() => {
    if (!trackersOutput) {
      return [];
    }

    return trackersOutput.items;
  }, [trackersOutput]);

  if (isLoading || areTrackersModelsLoading) {
    return <div>Идет загрузка....</div>;
  }

  if (error || trackersModelsError) {
    return (
      <>
        <div>Ошибка: не удалось загрузить данные по трекерам</div>
        {error && <div>{JSON.stringify(error)}</div>}
        {trackersModelsError && (
          <div>{JSON.stringify(trackersModelsError)}</div>
        )}
      </>
    );
  }

  if (isSuccess) {
    return (
      <TrackersTableInstance
        tableData={tableData}
        fetching={isPaginatingOrFiltration && !isLoading}
        count={trackersOutput?.total ?? 1}
        models={trackersModels}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        defaultPageSize={defaultPageSize}
      />
    );
  }

  return <>Не удалось загрузить данные по трекерам</>;
};
