import { baseApi as api } from '../../../base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getParksApiParksGet: build.query<
      GetParksApiParksGetApiResponse,
      GetParksApiParksGetApiArg
    >({
      query: () => ({ url: `/api/parks` }),
    }),
    getCounterpartyInfoApiCounterpartyGet: build.query<
      GetCounterpartyInfoApiCounterpartyGetApiResponse,
      GetCounterpartyInfoApiCounterpartyGetApiArg
    >({
      query: () => ({ url: `/api/counterparty` }),
    }),
    getCounterpartyInfoApiCounterpartiesGet: build.query<
      GetCounterpartyInfoApiCounterpartiesGetApiResponse,
      GetCounterpartyInfoApiCounterpartiesGetApiArg
    >({
      query: () => ({ url: `/api/counterparties` }),
    }),
    getManufacturesApiVehiclesManufacturesGet: build.query<
      GetManufacturesApiVehiclesManufacturesGetApiResponse,
      GetManufacturesApiVehiclesManufacturesGetApiArg
    >({
      query: () => ({ url: `/api/vehicles/manufactures` }),
    }),
    getManufacturesApiVehiclesModelsGet: build.query<
      GetManufacturesApiVehiclesModelsGetApiResponse,
      GetManufacturesApiVehiclesModelsGetApiArg
    >({
      query: () => ({ url: `/api/vehicles/models` }),
    }),
    getStatusesApiVehiclesStatusesGet: build.query<
      GetStatusesApiVehiclesStatusesGetApiResponse,
      GetStatusesApiVehiclesStatusesGetApiArg
    >({
      query: () => ({ url: `/api/vehicles/statuses` }),
    }),
    getStatusesApiVehiclesTypesGet: build.query<
      GetStatusesApiVehiclesTypesGetApiResponse,
      GetStatusesApiVehiclesTypesGetApiArg
    >({
      query: () => ({ url: `/api/vehicles/types` }),
    }),
    getGroupsApiTrackersModelsGet: build.query<
      GetGroupsApiTrackersModelsGetApiResponse,
      GetGroupsApiTrackersModelsGetApiArg
    >({
      query: () => ({ url: `/api/trackers/models` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetParksApiParksGetApiResponse =
  /** status 200 Successful Response */ ParkOutput[];
export type GetParksApiParksGetApiArg = void;
export type GetCounterpartyInfoApiCounterpartyGetApiResponse =
  /** status 200 Successful Response */ CounterpartyOutput;
export type GetCounterpartyInfoApiCounterpartyGetApiArg = void;
export type GetCounterpartyInfoApiCounterpartiesGetApiResponse =
  /** status 200 Successful Response */ CounterpartyOutput[];
export type GetCounterpartyInfoApiCounterpartiesGetApiArg = void;
export type GetManufacturesApiVehiclesManufacturesGetApiResponse =
  /** status 200 Successful Response */ VehicleManufacturesRead[];
export type GetManufacturesApiVehiclesManufacturesGetApiArg = void;
export type GetManufacturesApiVehiclesModelsGetApiResponse =
  /** status 200 Successful Response */ VehicleModelsRead[];
export type GetManufacturesApiVehiclesModelsGetApiArg = void;
export type GetStatusesApiVehiclesStatusesGetApiResponse =
  /** status 200 Successful Response */ VehicleStatusesRead[];
export type GetStatusesApiVehiclesStatusesGetApiArg = void;
export type GetStatusesApiVehiclesTypesGetApiResponse =
  /** status 200 Successful Response */ VehicleTypesRead[];
export type GetStatusesApiVehiclesTypesGetApiArg = void;
export type GetGroupsApiTrackersModelsGetApiResponse =
  /** status 200 Successful Response */ TrackerModelOutput[];
export type GetGroupsApiTrackersModelsGetApiArg = void;
export type GroupOutput = {
  id: number;
  name: string;
};
export type ParkOutput = {
  id: number;
  name: string;
  counterparty_id: number;
  coordinates?: string | null;
  groups: GroupOutput[];
};
export type CounterpartyOutput = {
  id: number;
  name: string;
  ogrn?: string | null;
  contract_number?: string | null;
};
export type VehicleManufacturesRead = {
  id: number;
  name: string;
};
export type VehicleModelsRead = {
  id: number;
  name: string;
};
export type VehicleStatusesRead = {
  id: number;
  name: string;
};
export type VehicleTypesRead = {
  id: number;
  name: string;
};
export type TrackerModelOutput = {
  id: number;
  name: string;
};
export const {
  useGetParksApiParksGetQuery,
  useGetCounterpartyInfoApiCounterpartyGetQuery,
  useGetCounterpartyInfoApiCounterpartiesGetQuery,
  useGetManufacturesApiVehiclesManufacturesGetQuery,
  useGetManufacturesApiVehiclesModelsGetQuery,
  useGetStatusesApiVehiclesStatusesGetQuery,
  useGetStatusesApiVehiclesTypesGetQuery,
  useGetGroupsApiTrackersModelsGetQuery,
} = injectedRtkApi;
