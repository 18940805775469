import {
  useGetGroupsQuery,
  useGetManufacturersQuery,
  useGetVehicleModelsQuery,
  useGetVehicleStatusesQuery,
  useGetVehicleTypesQuery,
} from '@shared/api/services/common/enhanced';
import { useGetAllOfTheTrackersQuery } from '@shared/api/services/tracker/enhanced';
import { useMemo } from 'react';
import { ElectricBusTableData } from '../consts/types';
import {
  getParkByGroupId,
  getParkNameByGroupId,
} from '@entities/vehicle/lib/get-park-name-by-group-id';
import { useCounterparties } from '@shared/hooks/use-counterparties';
import { getCounterpartyIdByParkId } from '@entities/vehicle/lib/get-counterparty-id-by-park-id';
import { useGetHotStorageTelemetryQuery } from '@shared/api/services/telemetry/enhanced';
import { useGetElectricbusesQuery } from '@shared/api/services/electricbus/enhanced';

type Props = {
  eParkId?: number[];
  groupIds: number[];
};

export const useElectricbusesData = ({ groupIds, eParkId }: Props) => {
  const {
    data: parks = [],
    isLoading: isParksLoading,
    error: parksError,
    isSuccess: isParksSuccess,
  } = useGetGroupsQuery(undefined);

  const parkId = useMemo(() => {
    return eParkId ?? parks.map(({ id }) => id);
  }, [eParkId, parks, isParksSuccess]);

  const {
    data: electricbuses,
    isLoading: isElectricbusesLoading,
    error: electricbusesError,
    isSuccess: isElectricbusesSuccess,
  } = useGetElectricbusesQuery(groupIds.length ? groupIds.join(',') : '', {
    pollingInterval: 5000,
  });

  const {
    data: trackersOutput,
    isLoading: isTrackersOutputLoading,
    error: trackersError,
    isSuccess: isTrackersOutputSuccess,
  } = useGetAllOfTheTrackersQuery(undefined, { pollingInterval: 5000 });

  const {
    data: manufacturers,
    isLoading: isManufacturersLoading,
    error: manufacturersError,
    isSuccess: isManufacturersSuccess,
  } = useGetManufacturersQuery();

  const {
    data: vehicleTypes,
    isLoading: isVehicleTypesLoading,
    error: vehicleTypesError,
    isSuccess: isVehicleTypesSuccess,
  } = useGetVehicleTypesQuery();

  const {
    data: vehicleModels,
    isLoading: isVehicleModelsLoading,
    error: vehicleModelsError,
    isSuccess: isVehicleModelsSuccess,
  } = useGetVehicleModelsQuery();

  const {
    data: vehicleStatuses,
    isLoading: isVehicleStatusesLoading,
    error: vehicleStatusesError,
    isSuccess: isVehicleStatusesSuccess,
  } = useGetVehicleStatusesQuery(undefined);

  const {
    data: telemetryOutput,
    isLoading: isTelemetryLoading,
    isSuccess: isTelemetrySuccess,
    error: telemetryError,
  } = useGetHotStorageTelemetryQuery(parkId.join(','), {
    pollingInterval: 5000,
  });

  const {
    counterparty,
    counterparties,
    isCounterpartiesDataLoading,
    counterpartiesDataError,
    isCounterpartiesDataSuccess,
    isStaff,
  } = useCounterparties();

  const isLoading =
    isManufacturersLoading ||
    isVehicleModelsLoading ||
    isVehicleTypesLoading ||
    isVehicleStatusesLoading ||
    isTelemetryLoading ||
    isCounterpartiesDataLoading ||
    isTrackersOutputLoading ||
    isParksLoading ||
    isElectricbusesLoading;

  const error = [
    manufacturersError,
    vehicleTypesError,
    vehicleModelsError,
    vehicleStatusesError,
    telemetryError,
    counterpartiesDataError,
    trackersError,
    electricbusesError,
    parksError,
  ].find((err) => err !== undefined);

  const isSuccess =
    isVehicleTypesSuccess &&
    isVehicleModelsSuccess &&
    isManufacturersSuccess &&
    isVehicleStatusesSuccess &&
    isTelemetrySuccess &&
    isCounterpartiesDataSuccess &&
    isTrackersOutputSuccess &&
    isElectricbusesSuccess &&
    isParksSuccess;

  const tableData: ElectricBusTableData = useMemo(() => {
    if (
      electricbuses === undefined ||
      parks === undefined ||
      telemetryOutput === undefined
    )
      return [];

    return electricbuses.map(
      ({
        id,
        grn,
        garage_number,
        type_id,
        group_id,
        model_id,
        manufacture_id,
        status_id,
        last_charge_time,
      }) => {
        const tracker = trackersOutput?.find(({ ev_id }) => ev_id === id);
        const telemetry = telemetryOutput?.find(
          ({ tracker_imei }) => tracker_imei === tracker?.imei
        );

        return {
          id,
          grn,
          garage_number,
          last_charge_time,
          group_id,
          type: vehicleTypes?.find(({ id }) => type_id === id),
          park_name: getParkNameByGroupId(group_id, parks),
          park: getParkByGroupId(group_id, parks),
          model: vehicleModels?.find(({ id }) => model_id === id),
          manufacturer: manufacturers?.find(({ id }) => manufacture_id === id),
          status: vehicleStatuses?.find(({ id }) => id === status_id),
          tracker_status: tracker?.status,
          imei: tracker?.imei,
          firmware: tracker?.firmware,
          phone_number: tracker?.phone_number,
          soc: telemetry?.soc,
          soh: telemetry?.soh,
          power_reserve: telemetry?.power_reserve,
          telemetry,
          counterparty: isStaff
            ? counterparties?.find(
                (counterparty) =>
                  counterparty.id === getCounterpartyIdByParkId(group_id, parks)
              )?.name ?? ''
            : counterparty?.name ?? '',
        };
      }
    );
  }, [electricbuses, trackersOutput, telemetryOutput]);

  return {
    vehicleStatuses,
    parks,
    tableData,
    isLoading,
    isSuccess,
    error,
  };
};
