import { DatePicker } from 'antd';
import styled from 'styled-components';

const { RangePicker } = DatePicker;

export const FormLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 230px);
  column-gap: 80px;
`;

export const FormButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 24px 0 40px 0;

  border-top: 1px solid rgba(163, 174, 196, 0.4);
`;

export const CustomedRangePicker = styled(RangePicker)`
  background-color: rgba(255, 255, 255, 0.08);
  height: 41px;
  border-radius: 12px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid white;
  }

  &:focus {
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid white;
  }

  &.ant-picker-focused {
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid white;
  }

  & .ant-picker-active-bar {
    background-color: white;
  }
`;
