import { FC } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { ConfigProvider, DatePicker, GetProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import locale from 'antd/locale/ru_RU';
import theme from 'antd/es/theme';

import { UiButton } from '@shared/ui/ui-button';
import { Controller, useForm } from 'react-hook-form';
import {
  FieldErrorContainer,
  FormColumn,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';
import {
  DownloadArchiveFormSchemaType,
  DownloadArchiveFormSchema,
} from '../../consts';
import { CustomInput } from '@shared/ui/custom-input';
import dayjs from 'dayjs';

import {
  FormButtonsContainer,
  FormLayout,
  CustomedRangePicker,
} from './styles';

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

const { RangePicker } = DatePicker;

type Props = {
  imei: string;
  closeModal: () => void;
  onSubmit: (data: DownloadArchiveFormSchemaType) => Promise<void>;
  isLoading: boolean;
};

export const DownloadArchiveForm: FC<Props> = ({
  imei,
  isLoading,
  onSubmit,
}) => {
  const defaultValues = {
    imei: imei,
  };

  const {
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    control,
    setValue,
  } = useForm<DownloadArchiveFormSchemaType>({
    resolver: zodResolver(DownloadArchiveFormSchema),
    defaultValues,
  });

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormLayout>
          <FormColumn>
            <FormItemContainer>
              <FormItemLabel>ID трекера</FormItemLabel>
              <Controller
                name="imei"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    inputSize="md"
                    placeholder="Введите значение"
                    value={imei}
                    disabled={true}
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.imei && <p>{errors.imei.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
            <FormItemContainer>
              <FormItemLabel>За период:</FormItemLabel>
              <Controller
                name="range"
                control={control}
                render={({ field, fieldState }) => (
                  // @ts-ignore
                  <CustomedRangePicker
                    {...field}
                    onChange={(value, dateString) => {
                      // @ts-ignore
                      setValue('range', value);
                    }}
                    // @ts-ignore
                    minDate={dayjs().subtract(183, 'days')}
                    // @ts-ignore
                    maxDate={dayjs()}
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.range && <p>{errors.range.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
          </FormColumn>
        </FormLayout>

        <FormButtonsContainer>
          <UiButton variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? (
              <>
                <LoadingOutlined />
                {'  '}Скачать архив
              </>
            ) : (
              `Скачать архив`
            )}
          </UiButton>
        </FormButtonsContainer>
      </form>
    </ConfigProvider>
  );
};
