import type { LngLat, YMapLocationRequest } from '@yandex/ymaps3-types';
import React, {
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import ReactDOM from 'react-dom';

import { Telemetry } from '@entities/vehicle/model/telemetry';

import { ElectrobusPlacemark } from '../electrobus-placemark';
import {
  PortStationEnum,
  StationOutput,
  StationStatusEnum,
} from '@shared/api/services/integration/api';
import { Feature } from '@yandex/ymaps3-clusterer';
import { StationPlacemark } from '../station-placemark';

const SELECTED_VEHICLE_ZOOM_LEVEL = 15;
const DEFAULT_ZOOM_LEVEL = 8;

type Props = {
  selectedTrackerImei?: string;
  onSelectVehicle: (trackerImei: string) => void;
  selectStations: (stations: StationOutput[]) => void;
  telemetry: Telemetry[];
  zoomLevel?: number;
  initialLatitude?: number;
  initialLongitude?: number;
  stationsData: StationOutput[];
};

const getPlacemarkZIndex = (percent: number) => {
  if (percent <= 30) {
    return 2;
  } else if (percent >= 31 && percent <= 70) {
    return 1;
  } else {
    return 0;
  }
};

export function Map({
  onSelectVehicle,
  selectStations,
  selectedTrackerImei,
  telemetry,
  zoomLevel,
  initialLatitude,
  initialLongitude,
  stationsData,
}: Props) {
  const [components, setComponents] = useState({});

  const [data, setData] = useState<{
    reactify: Object | null;
    ymaps: Object | null;
  }>({
    reactify: null,
    ymaps: null,
  });

  const [location, setLocation] = useState<YMapLocationRequest>({
    center: [initialLongitude ?? 37.61752, initialLatitude ?? 55.755865],
    zoom: zoomLevel ?? DEFAULT_ZOOM_LEVEL,
  });

  const mapRef = useRef(null);

  useEffect(() => {
    setLocation({
      center: [initialLongitude ?? 37.61752, initialLatitude ?? 55.755865],
      zoom: zoomLevel ?? DEFAULT_ZOOM_LEVEL,
    });
  }, [initialLatitude, initialLongitude, zoomLevel]);

  useEffect(() => {
    const script = document.createElement('script');

    document.body.appendChild(script);

    script.type = 'text/javascript';
    script.src = `https://api-maps.yandex.ru/v3/?apikey=6ebd426d-b0c3-468b-bbdd-8775edeaf4d2&lang=ru_RU`;
    script.onload = async () => {
      const ymaps = window.ymaps3;

      await ymaps.ready;

      const ymaps3Reactify = await ymaps.import('@yandex/ymaps3-reactify');

      const reactify = ymaps3Reactify.reactify.bindTo(React, ReactDOM);

      setData({
        reactify,
        ymaps,
      });
      /*
      and other logic which is not connected with rendering
      to load ymap modules like YMapDefaultMarker
      */
    };
  }, []);

  useEffect(() => {
    const loadComponents = async () => {
      if (data?.reactify) {
        const clustererModule = await ymaps3.import(
          '@yandex/ymaps3-clusterer@0.0.1'
        );
        const newComponents = {
          ...data.reactify.module(data.ymaps),
          ...data.reactify.module(clustererModule),
        };
        setComponents(newComponents);
      }
    };

    loadComponents();
  }, [data]);

  const memoizedComponents = useMemo(() => components, [components]);

  if (!memoizedComponents) {
    return <div>Загрузка...</div>;
  }

  const handlePlacemarkClick = (vehicle: Telemetry) => {
    onSelectVehicle(vehicle.tracker_imei);

    setLocation({
      center: [vehicle.longitude, vehicle.latitude],
      zoom: SELECTED_VEHICLE_ZOOM_LEVEL,
    });
  };

  const {
    YMap,
    YMapDefaultSchemeLayer,
    YMapDefaultFeaturesLayer,
    YMapMarker,
    YMapControls,
    YMapScaleControl,
    YMapListener,
    YMapClusterer,
    YMapFeatureDataSource,
    clusterByGrid,
    YMapLayer,
  } = memoizedComponents;

  const gridSizedMethod = useMemo(() => {
    if (clusterByGrid) {
      return clusterByGrid({ gridSize: 64 });
    }
  }, [clusterByGrid]);

  const marker = useCallback(
    (feature: Feature) => {
      const station = feature.properties as StationOutput | undefined;

      const ports =
        (station?.ports as Array<{
          number: number;
          status: PortStationEnum;
        }>) ?? [];
      const total = ports.length;
      const ready = ports.filter(({ status }) => status === 'READY').length;

      return (
        <YMapMarker
          key={feature.id}
          coordinates={feature.geometry.coordinates}
          source="clusterer-source"
        >
          <StationPlacemark
            ready={ready}
            total={total}
            stationsData={station ? [station] : []}
            selectStations={selectStations}
          />
        </YMapMarker>
      );
    },
    [memoizedComponents]
  );

  const cluster = useCallback(
    (coordinates: LngLat, features: Feature[]) => {
      const stations = features.map(
        (feature) => feature.properties
      ) as StationOutput[];

      const ports = stations.reduce((acc, curr) => {
        const currPorts =
          (curr.ports as Array<{
            number: number;
            status: PortStationEnum;
          }>) ?? [];
        return [...acc, ...currPorts];
      }, [] as Array<{ number: number; status: PortStationEnum }>);

      const total = ports.length;
      const ready = ports.filter(({ status }) => status === 'READY').length;

      return (
        <YMapMarker
          key={`${features[0].id}-${features.length}`}
          coordinates={coordinates}
          source="clusterer-source"
        >
          <StationPlacemark
            ready={ready}
            total={total}
            stationsData={stations}
            selectStations={selectStations}
          />
        </YMapMarker>
      );
    },
    [memoizedComponents]
  );

  if (!YMap || !YMapClusterer || !clusterByGrid) {
    return null;
  }

  const points = stationsData.map((station) => {
    const { station_id, longitude, latitude } = station;
    return {
      type: 'Feature',
      id: station_id,
      geometry: { type: 'Point', coordinates: [longitude, latitude] },
      properties: station,
    };
  });

  const onUpdate = (props) => {
    const { location, mapInAction } = props;

    if (!mapInAction) {
      setLocation({
        center: location.center,
        zoom: location.zoom,
      });
    }
  };

  return (
    <YMap location={location} mode="vector" theme="dark" ref={mapRef}>
      <YMapDefaultSchemeLayer />
      <YMapDefaultFeaturesLayer />
      <YMapFeatureDataSource id="clusterer-source" />
      {/* Add the layer for markers and the clusterer */}
      <YMapLayer source="clusterer-source" type="markers" zIndex={1800} />
      <YMapClusterer
        marker={marker}
        cluster={cluster}
        method={gridSizedMethod}
        features={points}
      />

      <YMapListener onUpdate={onUpdate} />

      {telemetry.map((vehicle) => {
        const {
          latitude,
          longitude,
          pre_latitude,
          pre_longitude,
          soc,
          tracker_imei,
        } = vehicle;

        return (
          <YMapMarker
            coordinates={[longitude, latitude]}
            onClick={() => handlePlacemarkClick(vehicle)}
            zIndex={getPlacemarkZIndex(soc)}
            key={tracker_imei}
          >
            <ElectrobusPlacemark
              soc={soc}
              selected={tracker_imei === selectedTrackerImei}
              zoomLevel={location.zoom}
              key={tracker_imei}
              imei={tracker_imei}
              latitude={latitude}
              longitude={longitude}
              preLatitude={pre_latitude}
              preLongitude={pre_longitude}
            />
          </YMapMarker>
        );
      })}

      <YMapControls position="bottom left">
        <YMapScaleControl />
      </YMapControls>
    </YMap>
  );
}
