export const idTitleColumnVehicleTelMap: Record<string, string> = {
  park_name: 'Парк',
  imei: 'ID трекера',
  grz: 'ГРЗ',
  garage_number: 'Гаражный номер',
  time: 'Время',
  coordinates: 'Координаты',
  firmware: 'Прошивка трекера',
  soh: 'SoH',
  soc: 'SoC',
  charge_current_A: 'Суммарный ток заряда',
  discharge_current_A: 'Суммарный ток разряда',
  permissible_charge_current: 'Допустимый ток заряда',
  permissible_discharge_current: 'Допустимый ток разряда',
  power_reserve: 'Запас хода (км)',
  last_charge: 'Время и дата последней зарядки',
  ctts: 'Температура контура охлаждения (CTTS)',
  interval_telemetry_S: 'Интервал обновления телеметрии (сек)',
  count_charge_session: 'Кол-во циклов заряда/разряда',
  errors: 'Ошибки',
  batteries: 'Батареи',
};

export const idTitleColumnBatteryTelMap: Record<string, string> = {
  imei: 'ID трекера',
  grz: 'ГРЗ',
  garage_number: 'Гаражный номер',
  number_battery: 'Номер батареи',
  min_voltage_V: 'Мин. напряжение',
  avg_voltage_V: 'Сред. напряжение',
  max_voltage_V: 'Макс. напряжение',
  min_temperature_C: 'Мин. температура',
  max_temperature_C: 'Макс. температура',
  coolant_temp_C: 'Текущая температура',
  battery_main_contactor: 'Состояние контакторов',
  firmware_bms: 'Прошивка BMS',
  errors: 'Ошибки',
};
