import NiceModal from '@ebay/nice-modal-react';

import { AddIcon } from '@shared/icons/add';

import { AddVehicleModal } from '@features/vehicle/add/ui/modal';

import { ButtonLabel, StyledButton } from './styles';
import { useRole } from '@entities/user/lib/hooks/use-role';

export function AddVehicleButton() {
  const { isDispatcher, isAdmin, isLoading } = useRole();
  const showAddModal = () => {
    NiceModal.show(AddVehicleModal, {});
  };

  const showButton = isDispatcher || isAdmin;

  if (isLoading) {
    return null;
  }

  if (showButton) {
    return (
      <StyledButton
        onClick={() => {
          showAddModal();
        }}
      >
        <ButtonLabel>Добавить ТС</ButtonLabel>
        <AddIcon />
      </StyledButton>
    );
  }

  return null;
}
