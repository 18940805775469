import { useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';

import { QUERY_PARAM } from '@shared/consts/server';
import { usePaginationState, useSortingState } from '@shared/ui/table/hooks';
import { ServerTableLayout } from '@shared/ui/table/layout';
import {
  GetParksApiParksGetApiResponse,
  GetStatusesApiVehiclesStatusesGetApiResponse,
  GroupOutput,
  ParkOutput,
  VehicleManufacturesRead,
  VehicleModelsRead,
  VehicleStatusesRead,
  VehicleTypesRead,
} from '@shared/api/services/common/api';
import { Electricbus } from '@shared/api/services/electricbus/types';

import { useColumns } from '../../hooks/use-columns';
import { TRANSPORT } from '@shared/consts/routes';
import { SortDirection } from '@shared/api/services/telemetry/api';
import { ElectricBusTableData } from '../../consts/types';
import { useSortVehicleData } from '../../hooks/use-sort-vehicle-data';

type VehicleTableType = 'TRANSPORT' | 'PARK' | 'CONVOY';

type Props = {
  tableData: ElectricBusTableData;
  loading: boolean;
  count: number | undefined;
  statuses?: GetStatusesApiVehiclesStatusesGetApiResponse;
  parks?: GetParksApiParksGetApiResponse;
  tableType: VehicleTableType;
  defaultPageSize: number;
};

export function VehiclesListTableInstance({
  tableData,
  loading,
  count,
  statuses,
  parks,
  tableType,
  defaultPageSize,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const columns = useColumns({
    statuses,
  });

  const sorting = useSortingState();
  const pagination = usePaginationState();

  const page = searchParams.get(QUERY_PARAM.page);
  const size = searchParams.get(QUERY_PARAM.limit);
  const ordering = searchParams.get(QUERY_PARAM.ordering);
  const type = searchParams.get(QUERY_PARAM.type) as SortDirection | null;
  const sortedField = searchParams.get(QUERY_PARAM.ordering);
  const sortedBy = searchParams.get(QUERY_PARAM.type);

  const sortedData = useSortVehicleData({ tableData, sortedBy, sortedField });

  const paginatedData = useMemo(() => {
    const currentPage = Number(page ?? 1);
    const currentSize = Number(size ?? defaultPageSize);

    const startIdx = (currentPage - 1) * currentSize;
    const endIdx = startIdx + currentSize;
    return sortedData.slice(startIdx, endIdx);
  }, [sortedData, size, page]);

  const table = useReactTable({
    data: paginatedData,
    columns,
    state: {
      sorting,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (size ? Number(size) : defaultPageSize))
      : undefined,
  });

  const handleRowDoubleClick = (row) => {
    const convoyId = row.original.group_id;
    const vehicleId = row.original.id;
    const parkId = parks.find((park) =>
      park.groups?.some(({ id }) => id === convoyId)
    )?.id!;

    if (tableType === 'TRANSPORT') {
      navigate(`${TRANSPORT}/${parkId}/${convoyId}/${vehicleId}/details`);
      return;
    }

    if (tableType === 'PARK') {
      navigate(`${TRANSPORT}/${parkId}/${convoyId}/${vehicleId}/details`);
      return;
    }

    if (tableType === 'CONVOY') {
      navigate(`${TRANSPORT}/${parkId}/${convoyId}/${vehicleId}/details`);
      return;
    }
  };

  return (
    <ServerTableLayout
      handleRowDoubleClick={handleRowDoubleClick}
      loading={loading}
      table={table}
      rowsCount={count}
      defaultPageSize={defaultPageSize}
    />
  );
}

/*
  const sortedData = useMemo(() => {
    const clonedVehiclesData = JSON.parse(
      JSON.stringify(vehiclesData)
    ) as VehiclesTelemetryData;

    if (sortedBy === 'asc' && sortedField !== null) {
      if (sortedField === 'time' || sortedField === 'last_charge') {
        return clonedVehiclesData.sort((a, b) => {
          return (
            new Date(a[sortedField]).getTime() -
            new Date(b[sortedField]).getTime()
          );
        });
      }
      return clonedVehiclesData.sort((a, b) => a[sortedField] - b[sortedField]);
    }

    if (sortedBy === 'desc' && sortedField !== null) {
      if (sortedField === 'time' || sortedField === 'last_charge') {
        return clonedVehiclesData.sort((a, b) => {
          return (
            new Date(b[sortedField]).getTime() -
            new Date(a[sortedField]).getTime()
          );
        });
      }
      return clonedVehiclesData.sort((a, b) => b[sortedField] - a[sortedField]);
    }

    return clonedVehiclesData;
  }, [vehiclesData, sortedField, sortedBy]);

*/
