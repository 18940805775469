import { countPageSize } from '@shared/ui/table/lib/count-page-size';
import { useData } from '../../hooks/use-data';
import { UsersTableInstance } from '../table';

export const ConnectedUsersTable = () => {
  const {
    profile,
    counterparty,
    users,
    counterparties,
    parks,
    isLoading,
    isSuccess,
    error,
  } = useData();

  if (isLoading) {
    return <>Загрузка...</>;
  }

  if (profile === undefined || profile === null) {
    return (
      <>
        <p>Ошибка: не удалось определить пользователя</p>
      </>
    );
  }

  if (error && !isSuccess) {
    return (
      <>
        <p>Ошибка: не удалось загрузить данные</p>
        {error && <p>{JSON.stringify(error)}</p>}
      </>
    );
  }

  return (
    <UsersTableInstance
      users={users ?? []}
      counterparties={counterparties ?? []}
      counterparty={counterparty}
      profile={profile}
      parks={parks ?? []}
      count={users!.length}
      defaultPageSize={countPageSize()}
      loading={isLoading}
    />
  );
};
