import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';
import { isErrorWithMessage } from '@shared/lib/http/is-error-with-message';
import { useCreateUserMutation } from '@shared/api/services/user/enhanced';

import { ADD_SUCCESS, ADD_ERROR } from '../../const';

import { ConnectedUserForm } from '@entities/user/ui/connected-user-form';
import { CreatingFormSchemaType } from '@entities/user/consts/schemas';

type Props = {
  closeModal: () => void;
};

export function AddUser({ closeModal }: Props) {
  const [trigger, { isLoading }] = useCreateUserMutation();

  const handleSubmit = async (data: CreatingFormSchemaType) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(`${ADD_SUCCESS} ${res.username}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.detail : ADD_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  return (
    <ConnectedUserForm
      formType="creating"
      isProfile={false}
      //  submitButtonLabel="Создать"
      handleFormSubmit={handleSubmit}
      loading={isLoading}
    />
  );
}
