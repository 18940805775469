import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { DownloadButton } from '../../download-archive/ui/download-button';

import { VehicleTelemetryData } from '../consts/types';
import { getTableSettingsColumn } from '@shared/ui/table/settings-column/get-table-settings-column';
import { idTitleColumnVehicleTelMap } from '../consts/id-title-column-map';
import dayjs from 'dayjs';
import { BatteryMarkersPanel } from '@entities/battery/ui/battery-markers-panel';
import { BatteryErrorsPanel } from '@entities/battery/ui/battery-errors-panel';
import { SohCell } from '@entities/vehicle/ui/soh-cell';
import { SocCell } from '@entities/vehicle/ui/soc-cell';

const columnHelper = createColumnHelper<VehicleTelemetryData>();

export const useVehicleTelemetryColumns = () => {
  const [settings, setSettings] = useState<Record<string, boolean>>({
    park_name: true,
    imei: true,
    grz: true,
    garage_number: true,
    time: true,
    coordinates: true,
    firmware: true,
    soh: true,
    soc: true,
    charge_current_A: true,
    discharge_current_A: true,
    permissible_charge_current: true,
    permissible_discharge_current: true,
    power_reserve: true,
    last_charge: true,
    ctts: true,
    interval_telemetry_S: true,
    count_charge_session: true,
    errors: true,
    batteries: true,
  });

  const DATA_COLUMNS = [
    columnHelper.accessor('park_name', {
      id: 'park_name',
      header: 'Парк',
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: true,
    }),
    columnHelper.accessor('imei', {
      id: 'imei',
      header: 'ID трекера',
      cell: (props) => {
        return props.getValue();
      },
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: true,
    }),
    columnHelper.accessor('grz', {
      id: 'grz',
      header: 'ГРЗ',
      cell: (props) => {
        return props.getValue();
      },
      size: 130,
      minSize: 130,
      maxSize: 130,
      enableSorting: false,
    }),
    columnHelper.accessor('garage_number', {
      id: 'garage_number',
      header: (
        <>
          <p>Гаражный</p>
          <p>номер</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 130,
      minSize: 130,
      maxSize: 130,
      enableSorting: true,
    }),
    columnHelper.accessor('time', {
      id: 'time',
      header: 'Время',
      cell: (props) => {
        const value = props.getValue();

        if (value === undefined || value === null) {
          return 'Нет данных';
        }

        // const formattedDate = value?.includes('Z') ? value : `${value}Z`;
        const [date, time] = dayjs(value).format('YYYY.MM.DD HH:mm').split(' ');

        return `${time},  ${date}`;
      },
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: true,
    }),
    columnHelper.accessor('coordinates', {
      id: 'coordinates',
      header: 'Координаты',
      cell: (props) => {
        const { latitude, longitude } = props.getValue();
        return `${latitude}, ${longitude}`;
      },
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: false,
    }),
    columnHelper.accessor('firmware', {
      id: 'firmware',
      header: (
        <>
          <p>Прошивка</p>
          <p>трекера</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 130,
      minSize: 130,
      maxSize: 130,
      enableSorting: true,
    }),
    columnHelper.accessor('soh', {
      id: 'soh',
      header: 'SoH',
      cell: SohCell,
      size: 130,
      minSize: 130,
      maxSize: 130,
      enableSorting: true,
    }),
    columnHelper.accessor('soc', {
      id: 'soc',
      header: 'SoC',
      cell: SocCell,
      size: 130,
      minSize: 130,
      maxSize: 130,
      enableSorting: true,
    }),
    columnHelper.accessor('charge_current_A', {
      id: 'charge_current_A',
      header: (
        <>
          <p>Суммарный</p>
          <p>ток заряда</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 140,
      minSize: 140,
      maxSize: 140,
      enableSorting: true,
    }),
    columnHelper.accessor('discharge_current_A', {
      id: 'discharge_current_A',
      header: (
        <>
          <p>Суммарный</p>
          <p>ток разряда</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 140,
      minSize: 140,
      maxSize: 140,
      enableSorting: true,
    }),
    columnHelper.accessor('permissible_charge_current', {
      id: 'permissible_charge_current',
      header: (
        <>
          <p>Допустимый</p>
          <p>ток заряда</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 140,
      minSize: 140,
      maxSize: 140,
      enableSorting: true,
    }),
    columnHelper.accessor('permissible_discharge_current', {
      id: 'permissible_discharge_current',
      header: (
        <>
          <p>Допустимый</p>
          <p>ток разряда</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 140,
      minSize: 140,
      maxSize: 140,
      enableSorting: true,
    }),
    columnHelper.accessor('power_reserve', {
      id: 'power_reserve',
      header: (
        <>
          <p>Запас</p>
          <p>хода (км)</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 120,
      minSize: 120,
      maxSize: 120,
      enableSorting: true,
    }),
    columnHelper.accessor('last_charge', {
      id: 'last_charge',
      header: (
        <>
          <p>Время и дата</p>
          <p>последней зарядки</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        const value = props.getValue();

        if (value === undefined || value === null) {
          return 'Нет данных';
        }

        // const formattedDate = value?.includes('Z') ? value : `${value}Z`;
        const [date, time] = dayjs(value).format('YYYY.MM.DD HH:mm').split(' ');

        return `${time},  ${date}`;
      },
      size: 200,
      minSize: 200,
      maxSize: 200,
      enableSorting: true,
    }),
    columnHelper.accessor('ctts', {
      id: 'ctts',
      header: (
        <>
          <p>Температура контура</p>
          <p>охлаждения (CTTS)</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 210,
      minSize: 210,
      maxSize: 210,
      enableSorting: true,
    }),
    columnHelper.accessor('interval_telemetry_S', {
      id: 'interval_telemetry_S',
      header: (
        <>
          <p>Интервал обновления</p>
          <p>телеметрии (сек)</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 220,
      minSize: 220,
      maxSize: 220,
      enableSorting: true,
    }),
    columnHelper.accessor('count_charge_session', {
      id: 'count_charge_session',
      header: (
        <>
          <p>Кол-во циклов</p>
          <p>заряда/разряда</p>
        </>
      ) as unknown as string,
      cell: (props) => {
        return props.getValue();
      },
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: true,
    }),
    columnHelper.accessor('errors', {
      id: 'errors',
      header: 'Ошибки',
      cell: (props) => {
        const value = props.getValue();
        if (value === undefined || value === null) return 'Нет данных';
        if (value.length === 0) return 'Ошибок нет';
        return <BatteryErrorsPanel errors={value} />;
      },
      size: 400,
      minSize: 400,
      maxSize: 400,
      enableSorting: false,
    }),
    columnHelper.accessor('batteries', {
      id: 'batteries',
      header: 'Батареи',
      cell: (props) => {
        const value = props.getValue();
        if (value === undefined || value === null) return 'Нет данных';
        return <BatteryMarkersPanel batteries={value} />;
      },
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: false,
    }),
  ];

  const settingsColumn = getTableSettingsColumn({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      const typedId = id!;
      return {
        key: typedId,
        label: idTitleColumnVehicleTelMap[typedId],
        isChecked: settings[typedId],
      };
    }),
    settings,
    renderCell: (props) => {
      const imei = props.row.original.imei;

      return <DownloadButton imei={imei} />;
    },
    setVisibleColumns: (cols: Record<string, boolean>) => {
      setSettings(cols);
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter(({ id }) => {
          const typedId = id!;
          return settings[typedId];
        })
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
