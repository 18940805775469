import { FC } from 'react';
import { GroupOperationsButtonStyled } from './styles';
import NiceModal from '@ebay/nice-modal-react';
import { GroupOperationsModal } from '../modal';
import { RowSelectionState } from '@tanstack/react-table';
import { useRole } from '@entities/user/lib/hooks/use-role';

type Props = {
  selectedImeis: string[];
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
};

export const GroupOperationsButton: FC<Props> = ({
  selectedImeis,
  setRowSelection,
}) => {
  const { isDispatcher, isAdmin, isLoading } = useRole();

  const showButton = isDispatcher || isAdmin;

  if (isLoading) {
    return null;
  }

  const isToBeDisabled = !selectedImeis.length;

  const showGroupOperationsModal = () => {
    NiceModal.show(GroupOperationsModal, { selectedImeis, setRowSelection });
  };
  if (showButton) {
    return (
      <GroupOperationsButtonStyled
        variant={isToBeDisabled ? 'outline' : 'primary'}
        onClick={() => {
          if (isToBeDisabled) return;
          showGroupOperationsModal();
        }}
      >
        Изменить
      </GroupOperationsButtonStyled>
    );
  }

  return null;
};
