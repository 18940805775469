import NiceModal from '@ebay/nice-modal-react';

import { EditIcon } from '@shared/icons/edit';

import { StyledButton } from './styles';
import { EditUserModal } from '../modal';
import { UserBaseInfoRead } from '@shared/api/services/user/api';

type Props = {
  user: UserBaseInfoRead;
};

export function EditUserButton({ user }: Props) {
  const showModal = () => {
    NiceModal.show(EditUserModal, { user, isProfile: false });
  };

  return (
    <StyledButton onClick={() => showModal()}>
      <EditIcon />
    </StyledButton>
  );
}
