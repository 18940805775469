export function LockIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.66602"
        y="6.41669"
        width="14.6667"
        height="12.8333"
        rx="3"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <circle
        cx="10.9993"
        cy="12.8333"
        r="1.83333"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M14.6673 6.41667C14.6673 4.39162 13.0257 2.75 11.0007 2.75C8.97561 2.75 7.33398 4.39162 7.33398 6.41667"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
}
