import styled from 'styled-components';

export const BatteriesMarkersWrapper = styled.div`
  display: flex;

  width: 100%;
  height: 18px;
  padding-top: 1px;
  padding-bottom: 1px;
`;

export const BatteryMarker = styled.div<{ isFirst: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #22b0f8;
  cursor: pointer;
  margin-left: ${({ isFirst }) => (isFirst ? '0px' : '5px')};

  &:hover {
    border: 1px solid white;
  }
`;

// вот дальше - это уже для тултипа

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;

export const BatteryNumber = styled.p``;

export const BatteryInfoWrapper = styled.div`
  display: flex;
  width: 500px;
`;

export const VoltageBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TemperatureBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

export const BlockTitle = styled.p`
  font-weight: 700;
`;

export const InfoField = styled.p``;
