import { useGetUsersInfoQuery } from '@shared/api/services/user/enhanced';
import { rolesMap, RoleEnum } from '../../consts/roles-map';

export const useRole = (): {
  isOperator: boolean;
  isDispatcher: boolean;
  isAdmin: boolean;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const { data, isLoading, isSuccess, error } = useGetUsersInfoQuery();

  const isOperator = data?.role_id === RoleEnum.OPERATOR;
  const isDispatcher = data?.role_id === RoleEnum.DISPATCHER;
  const isAdmin = data?.role_id === RoleEnum.ADMIN;

  return {
    isOperator,
    isDispatcher,
    isAdmin,
    isLoading,
    isSuccess,
  };
};
