import { CellContext } from '@tanstack/react-table';

import { Transport } from '@entities/transport/model/types';

import { NoInfo, StyledCell } from './styles';

// TODO: accessor
export function SohCell(props: CellContext<Transport, Transport['soh']>) {
  const val = props.getValue();

  if (!val) {
    return <NoInfo>Нет данных</NoInfo>;
  }

  return <StyledCell percent={val}>{val} %</StyledCell>;
}
