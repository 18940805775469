import { useEffect, useMemo } from 'react';
import {
  ElectricBusTableData,
  VehicleFilterType,
} from '../../../features/vehicle/vehicles-table/consts/types';
import { useDispatch } from 'react-redux';
import { setFilters } from '@shared/redux/slices/vehicle/filters';
import { preprocessGroupIdsArg } from '../lib/preprocess-group-ids-arg';
import { useParams } from 'react-router-dom';
import { GetParksApiParksGetApiResponse } from '@shared/api/services/common/api';
import { VehicleTableType } from '../model/vehicle-table-type';

type Props = {
  tableType: VehicleTableType;
  tableData: ElectricBusTableData;
  filters: VehicleFilterType;
  parks: GetParksApiParksGetApiResponse;
};

export const useFilterData = ({
  tableType,
  parks,
  tableData,
  filters,
}: Props) => {
  const { eParkId, convoyId, eBusId } = useParams();

  const dispatch = useDispatch();

  const {
    manufactureIds,
    modelsIds,
    statusEvIds,
    typeId,
    sohFrom,
    sohTo,
    socFrom,
    socTo,
    powerReserveFrom,
    powerReserveTo,
  } = filters;

  useEffect(() => {
    if (tableType === 'TRANSPORT') {
      dispatch(
        setFilters({
          ...filters,
          groupIds: preprocessGroupIdsArg(
            'TRANSPORT',
            eParkId,
            convoyId,
            parks
          ),
          parkId: undefined,
        })
      );
    }
    if (tableType === 'PARK') {
      dispatch(
        setFilters({
          ...filters,
          groupIds: preprocessGroupIdsArg('PARK', eParkId, convoyId, parks),
          parkId: Number(eParkId),
        })
      );
    }
    if (tableType === 'CONVOY' || tableType === 'ELECTROBUS') {
      dispatch(
        setFilters({
          ...filters,
          groupIds: preprocessGroupIdsArg('CONVOY', eParkId, convoyId, parks),
          parkId: Number(eParkId),
        })
      );
    }
  }, [tableType, eParkId, convoyId]);

  const filteredData = useMemo(() => {
    if (tableType === 'ELECTROBUS' && eBusId !== undefined) {
      return tableData.filter(({ id }) => id === eBusId);
    }

    return tableData.filter((tableDataItem) => {
      const { soc, soh, power_reserve, manufacturer, model, status, type } =
        tableDataItem;

      const bySocFrom =
        socFrom !== undefined
          ? soc !== undefined && soc !== null && soc >= socFrom
          : true;
      const bySocTo =
        socTo !== undefined
          ? soc !== undefined && soc !== null && soc <= socTo
          : true;
      const bySohFrom =
        sohFrom !== undefined
          ? soh !== undefined && soh !== null && soh >= sohFrom
          : true;
      const bySohTo =
        sohTo !== undefined
          ? soh !== undefined && soh !== null && soh <= sohTo
          : true;
      const byPowerReserveFrom =
        powerReserveFrom !== undefined
          ? power_reserve !== undefined &&
            power_reserve !== null &&
            power_reserve >= powerReserveFrom
          : true;
      const byPowerReserveTo =
        powerReserveTo !== undefined
          ? power_reserve !== undefined &&
            power_reserve !== null &&
            power_reserve <= powerReserveTo
          : true;
      const byManufacturerIds = manufactureIds.length
        ? manufacturer?.id !== undefined &&
          manufacturer?.id !== null &&
          manufactureIds.includes(manufacturer.id)
        : true;
      const byModelsIds = modelsIds.length
        ? model?.id !== undefined &&
          model?.id !== null &&
          modelsIds.includes(model.id)
        : true;
      const byStatusIds = statusEvIds.length
        ? status?.id !== undefined &&
          status.id !== null &&
          statusEvIds.includes(status.id)
        : true;
      const byVehicleType =
        typeId !== undefined
          ? type?.id !== undefined && type?.id !== null && type.id === typeId
          : true;

      return (
        bySocFrom &&
        bySocTo &&
        bySohFrom &&
        bySohTo &&
        byPowerReserveFrom &&
        byPowerReserveTo &&
        byManufacturerIds &&
        byModelsIds &&
        byStatusIds &&
        byVehicleType
      );
    });
  }, [
    tableData,
    manufactureIds,
    modelsIds,
    statusEvIds,
    typeId,
    sohFrom,
    sohTo,
    socFrom,
    socTo,
    powerReserveFrom,
    powerReserveTo,
    tableType,
    eBusId,
  ]);

  return filteredData;
};
