import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Spin } from 'antd';

import { useDeleteElectricbusMutation } from '@shared/api/services/electricbus/enhanced';
import { Electricbus } from '@shared/api/services/electricbus/types';

import { useDeleteUserMutation } from '@shared/api/services/user/enhanced';
import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';
import { ConfirmDialog } from '@shared/ui/confirm-dialog';
import { UiModal } from '@shared/ui/ui-modal';

import { DELETE_ERROR, DELETE_SUCCESS } from '../../consts';
import {
  DeleteModalContainer,
  DeleteModalInfo,
  DeleteModalText,
  DeleteModalTitle,
} from '@shared/ui/ui-modal/styles';
import { UserBaseInfoRead } from '@shared/api/services/user/api';
import { useDeleteTrackerMutation } from '@shared/api/services/tracker/enhanced';

type Props = {
  user: UserBaseInfoRead;
  closeParentModal: () => void;
};

export const DeleteUserModal = NiceModal.create(
  ({ user, closeParentModal }: Props) => {
    const modal = useModal();

    const { username } = user;

    const [trigger, { isLoading }] = useDeleteUserMutation();

    const handleSubmit = async () => {
      try {
        const res = await trigger(username).unwrap();

        openSuccessNotification(`${DELETE_SUCCESS} ${username}`);
      } catch (err) {
        const errorText = `${DELETE_ERROR} ${username}`;

        openErrorNotification(errorText);
      } finally {
        modal.hide();

        closeParentModal();
      }
    };

    return (
      <UiModal isOpen={modal.visible} onClose={modal.hide}>
        <UiModal.Body style={{ margin: '0', padding: '0', border: 'none' }}>
          <Spin spinning={isLoading}>
            <DeleteModalContainer>
              <DeleteModalInfo>
                <DeleteModalTitle>Подтверждение</DeleteModalTitle>
                <DeleteModalText>{`Вы уверены, что хотите удалить пользователя ${username} ?`}</DeleteModalText>
              </DeleteModalInfo>
              <ConfirmDialog
                onCancel={modal.hide}
                onConfirm={handleSubmit}
                loading={false}
              />
            </DeleteModalContainer>
          </Spin>
        </UiModal.Body>
      </UiModal>
    );
  }
);
