import { useState } from 'react';
import { TelemetryHeader } from '@widgets/header/ui/telemetry';

import { StandardPageLayout } from '../styles';
import { TelemetryConnectedTable } from '@features/telemetry/table/ui/connected-table';
import { TableOfTelemetryType } from '@features/telemetry/table/consts/types';

export const TelemetryPage = () => {
  const [selectedType, selectType] = useState<TableOfTelemetryType>('vehicle');

  return (
    <StandardPageLayout>
      <TelemetryHeader selectedType={selectedType} selectType={selectType} />
      <TelemetryConnectedTable tableType={selectedType} />
    </StandardPageLayout>
  );
};
