import { FC, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import {
  CounterpartyOutput,
  GetCounterpartyInfoApiCounterpartiesGetApiResponse,
  GetGroupsApiTrackersModelsGetApiResponse,
  GetParksApiParksGetApiResponse,
} from '@shared/api/services/common/api';
import { TrackerOutput } from '@shared/api/services/tracker/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { FormButtonsContainer, FormLayout } from './styles';
import { UiButton } from '@shared/ui/ui-button';
import {
  FieldErrorContainer,
  FormColumn,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';
import {
  CustomSelect,
  CustomSelectMultiple,
} from '@shared/ui/custom-select-new';
import { CustomInput } from '@shared/ui/custom-input';
import { UserBaseInfoRead } from '@shared/api/services/user/api';
import { EditingSchema, CreatingSchema } from '@entities/user/consts/schemas';
import { filterObject } from '@shared/lib/transormation/pick';
import { StyledCheckbox } from '@shared/ui/custom-checkbox/styled';

type Props = {
  formType: 'creating' | 'editing';
  initialValues?: UserBaseInfoRead;
  parks: GetParksApiParksGetApiResponse;
  counterparties?: GetCounterpartyInfoApiCounterpartiesGetApiResponse;
  counterparty?: CounterpartyOutput;
  handleFormSubmit: () => void;
  deleteButton?: JSX.Element;
  loading: boolean;
  profile: UserBaseInfoRead;
  isProfile: boolean;
};

const roles = [
  { label: 'Оператор', value: 1 },
  { label: 'Диспетчер', value: 2 },
  { label: 'Администратор', value: 3 },
];

const getParksOpts = (
  parks: GetParksApiParksGetApiResponse,
  counterpartyId?: number
) => {
  if (counterpartyId !== undefined) {
    return parks
      .filter(({ counterparty_id }) => counterparty_id === counterpartyId)
      .map(({ id, name }) => ({ label: name, value: id }));
  }
  return parks.map(({ id, name }) => ({ label: name, value: id }));
};

export const UserForm: FC<Props> = ({
  formType,
  initialValues,
  parks,
  counterparties,
  counterparty,
  handleFormSubmit,
  deleteButton,
  loading,
  profile,
  isProfile,
}) => {
  const [parksOpts, setParksOpts] = useState(getParksOpts(parks));
  const isFirstRender = useRef(true);

  const isEditingFormType = formType === 'editing';
  const isCreatingFormType = formType === 'creating';
  const isStaff = profile.is_staff;

  const {
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm({
    resolver: isEditingFormType
      ? zodResolver(EditingSchema)
      : zodResolver(CreatingSchema),
    defaultValues: { ...initialValues, password: undefined },
  });

  const available_park_ids = watch('available_park_ids');
  const counterpartyId = watch('counterparty_id');
  const password = watch('password');
  const surname = watch('surname');
  const lastName = watch('last_name');
  const firstName = watch('first_name');
  const isStaffCheckbox = watch('is_staff');

  const parksFieldToBeDisabled =
    counterpartyId === undefined || counterpartyId === null;

  useEffect(() => {
    if (counterpartyId !== undefined && counterpartyId !== null) {
      setParksOpts(getParksOpts(parks, counterpartyId));
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      setValue('available_park_ids', [], { shouldDirty: true });
    }
  }, [counterpartyId]);

  useEffect(() => {
    if (
      isStaff === false &&
      isCreatingFormType &&
      counterparty?.id !== null &&
      counterparty?.id !== undefined
    ) {
      setValue('counterparty_id', counterparty?.id, { shouldDirty: false });
    }
  }, [isStaff, counterparty?.id]);

  useEffect(() => {
    switch ('') {
      case password:
        setValue('password', undefined, { shouldDirty: true });
        return;
    }
  }, [password, surname, lastName, firstName]);

  const onSubmit = async (data: unknown) => {
    if (isCreatingFormType) {
      handleFormSubmit(data);
    } else {
      handleFormSubmit(filterObject(data, dirtyFields));
    }
  };

  return (
    <Spin spinning={loading}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormLayout>
          <FormColumn>
            <FormItemContainer>
              <FormItemLabel>Логин</FormItemLabel>
              <Controller
                name="username"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    disabled={isEditingFormType}
                    inputSize="md"
                    placeholder="Введите значение"
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.username && <p>{errors.username.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>

            <FormItemContainer>
              <FormItemLabel>Фамилия</FormItemLabel>
              <Controller
                name="last_name"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    inputSize="md"
                    placeholder="Введите значение"
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.last_name && <p>{errors.last_name.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>

            <FormItemContainer>
              <FormItemLabel>Пароль</FormItemLabel>
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    type="password"
                    inputSize="md"
                    placeholder="Введите значение"
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.password && <p>{errors.password.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>

            {!isProfile && (
              <FormItemContainer>
                <FormItemLabel>Парки</FormItemLabel>
                <Controller
                  name="available_park_ids"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomSelectMultiple {...field} options={parksOpts} />
                  )}
                  disabled={parksFieldToBeDisabled}
                />
                <FieldErrorContainer>
                  {errors?.available_park_ids && (
                    <p>{errors.available_park_ids.message}</p>
                  )}
                </FieldErrorContainer>
              </FormItemContainer>
            )}

            {isCreatingFormType && isStaff ? (
              <FormItemContainer>
                <Controller
                  name="is_staff"
                  control={control}
                  render={({ field, fieldState }) => (
                    <p>
                      <StyledCheckbox {...field} />
                      Сотрудник Ситроникс
                    </p>
                  )}
                />
              </FormItemContainer>
            ) : null}
          </FormColumn>

          <FormColumn>
            <FormItemContainer>
              <FormItemLabel>Имя</FormItemLabel>
              <Controller
                name="first_name"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    inputSize="md"
                    placeholder="Введите значение"
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.first_name && <p>{errors.first_name.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>

            <FormItemContainer>
              <FormItemLabel>Отчество</FormItemLabel>
              <Controller
                name="surname"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    inputSize="md"
                    placeholder="Введите значение"
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.surname && <p>{errors.surname.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>

            {!isProfile && (
              <FormItemContainer>
                <FormItemLabel>Роль</FormItemLabel>
                <Controller
                  name="role_id"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomSelect
                      {...field}
                      options={
                        isStaff
                          ? roles
                          : roles.filter(({ value }) => value !== 3)
                      }
                    />
                  )}
                />
                <FieldErrorContainer>
                  {errors?.role_id && <p>{errors.role_id.message}</p>}
                </FieldErrorContainer>
              </FormItemContainer>
            )}

            {!isProfile && (
              <FormItemContainer>
                <FormItemLabel>Контрагент</FormItemLabel>
                <Controller
                  name="counterparty_id"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomSelect
                      {...field}
                      options={
                        counterparties?.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        })) ?? [
                          {
                            value: counterparty?.id,
                            label: counterparty?.name,
                          },
                        ]
                      }
                      disabled={!isStaff}
                    />
                  )}
                />
                <FieldErrorContainer>
                  {errors?.counterparty_id && (
                    <p>{errors.counterparty_id.message}</p>
                  )}
                </FieldErrorContainer>
              </FormItemContainer>
            )}
          </FormColumn>
        </FormLayout>

        <FormButtonsContainer>
          {!isProfile && deleteButton}
          {/*<UiButton variant="outline">Сбросить</UiButton>*/}
          <UiButton variant="primary" type="submit">
            {isCreatingFormType ? 'Создать' : 'Сохранить'}
          </UiButton>
        </FormButtonsContainer>
      </form>
    </Spin>
  );
};
