import { useSearchParams } from 'react-router-dom';

export const usePaginationQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setPageParamFromJumper = (page: string) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    updatedSearchParams.set('page', page);

    setSearchParams(updatedSearchParams.toString());
  };

  const setPageParamFromControls = (type: 'prev' | 'next') => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    const currentPage = searchParams.get('page');

    if (currentPage === null) {
      const updatedPage = type === 'prev' ? Number(currentPage) - 1 : Number(2);

      updatedSearchParams.set('page', String(updatedPage));
    } else {
      const updatedPage =
        type === 'prev' ? Number(currentPage) - 1 : Number(currentPage) + 1;

      updatedSearchParams.set('page', String(updatedPage));
    }
    setSearchParams(updatedSearchParams.toString());
  };

  return {
    setPageParamFromJumper,
    setPageParamFromControls,
  };
};
