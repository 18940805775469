import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';
import { FiltersButton } from '@features/vehicle/filters';

import { HeaderLayout } from '../layout';
import { AddVehicleButton } from '../../../../features/vehicle/add/ui/add-vehicle-button';
import { ContentWrapper, Outer, TransportTitle } from './styles';
import { GetParksApiParksGetApiResponse } from '@shared/api/services/common/api';

type VehicleTableType = 'TRANSPORT' | 'PARK' | 'CONVOY';

type Props = {
  tableType: VehicleTableType;
};

const getTableVehicleTitle = (
  tableType: VehicleTableType,
  eParkId?: string,
  convoyId?: string,
  parks?: GetParksApiParksGetApiResponse
): string => {
  if (tableType === 'PARK') {
    return parks?.find(({ id }) => String(id) === eParkId)?.name ?? '';
  }

  if (tableType === 'CONVOY') {
    const park = parks?.find(({ id }) => String(id) === eParkId);
    const convoy = park?.groups?.find(({ id }) => String(id) === convoyId);

    return `${park?.name ?? ''}, ${convoy?.name ?? ''}`;
  }

  return 'Транспорт';
};

export const TransportHeader: FC<Props> = ({ tableType }) => {
  const { eParkId, convoyId } = useParams();

  const {
    data: parks,
    isLoading: isGroupsLoading,
    error: groupsError,
    isSuccess: isGroupsSuccess,
  } = useGetGroupsQuery();

  const title = useMemo(() => {
    return getTableVehicleTitle(tableType, eParkId, convoyId, parks);
  }, [tableType, eParkId, convoyId, parks]);

  const content = (
    <ContentWrapper>
      <TransportTitle>{title}</TransportTitle>
      <FiltersButton />
      <AddVehicleButton />
    </ContentWrapper>
  );

  return (
    <Outer>
      <HeaderLayout content={content} isStadardHeader={true} />
    </Outer>
  );
};
