import styled from 'styled-components';

export const EditProfileButtonWrapper = styled.button`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: inherit;
  width: 100%;
`;
