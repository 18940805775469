import { baseApi as api } from '../../../base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    apiAuthTokenAuthCreate: build.mutation<
      ApiAuthTokenAuthCreateApiResponse,
      ApiAuthTokenAuthCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/token-auth/`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    apiAuthTokenRefreshCreate: build.mutation<
      ApiAuthTokenRefreshCreateApiResponse,
      ApiAuthTokenRefreshCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/token-refresh/`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    apiAuthTokenVerifyCreate: build.mutation<
      ApiAuthTokenVerifyCreateApiResponse,
      ApiAuthTokenVerifyCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/token-verify/`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    apiUsersDeleteDestroy: build.mutation<
      ApiUsersDeleteDestroyApiResponse,
      ApiUsersDeleteDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/${queryArg}/delete/`,
        method: 'DELETE',
      }),
    }),
    apiUsersUpdatePartialUpdate: build.mutation<
      ApiUsersUpdatePartialUpdateApiResponse,
      ApiUsersUpdatePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/${queryArg.username}/update/`,
        method: 'PATCH',
        body: queryArg.patchedUserUpdate,
      }),
    }),
    apiUsersCreateCreate: build.mutation<
      ApiUsersCreateCreateApiResponse,
      ApiUsersCreateCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/create/`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    apiUsersListList: build.query<
      ApiUsersListListApiResponse,
      ApiUsersListListApiArg
    >({
      query: () => ({ url: `/api/users/list/` }),
    }),
    apiUsersSelfRetrieve: build.query<
      ApiUsersSelfRetrieveApiResponse,
      ApiUsersSelfRetrieveApiArg
    >({
      query: () => ({ url: `/api/users/self` }),
    }),
    apiUsersSelfPartialUpdate: build.mutation<
      ApiUsersSelfPartialUpdateApiResponse,
      ApiUsersSelfPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/self`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type ApiAuthTokenAuthCreateApiResponse =
  /** status 200  */ TokenObtainPairRead;
export type ApiAuthTokenAuthCreateApiArg = TokenObtainPairWrite;
export type ApiAuthTokenRefreshCreateApiResponse =
  /** status 200  */ TokenRefreshRead;
export type ApiAuthTokenRefreshCreateApiArg = TokenRefreshWrite;
export type ApiAuthTokenVerifyCreateApiResponse =
  /** status 200  */ TokenVerify;
export type ApiAuthTokenVerifyCreateApiArg = TokenVerifyWrite;
export type ApiUsersDeleteDestroyApiResponse = unknown;
export type ApiUsersDeleteDestroyApiArg = string;
export type ApiUsersUpdatePartialUpdateApiResponse =
  /** status 200  */ UserUpdate;
export type ApiUsersUpdatePartialUpdateApiArg = {
  username: string;
  patchedUserUpdate: PatchedUserUpdateWrite;
};
export type ApiUsersCreateCreateApiResponse = /** status 200  */ UserCreate;
export type ApiUsersCreateCreateApiArg = UserCreateWrite;
export type ApiUsersListListApiResponse = /** status 200  */ UserBaseInfoRead[];
export type ApiUsersListListApiArg = void;
export type ApiUsersSelfRetrieveApiResponse =
  /** status 200  */ UserBaseInfoRead;
export type ApiUsersSelfRetrieveApiArg = void;
export type ApiUsersSelfPartialUpdateApiResponse =
  /** status 200  */ UserBaseInfoRead;
export type ApiUsersSelfPartialUpdateApiArg = PatchedUserBaseInfo;
export type TokenObtainPair = {};
export type TokenObtainPairRead = {
  access: string;
  refresh: string;
};
export type TokenObtainPairWrite = {
  username: string;
  password: string;
};
export type TokenRefresh = {};
export type TokenRefreshRead = {
  access: string;
};
export type TokenRefreshWrite = {
  refresh: string;
};
export type TokenVerify = {};
export type TokenVerifyWrite = {
  token: string;
};
export type UserUpdate = {
  first_name?: string;
  last_name?: string;
  surname?: string;
  available_park_ids?: number[];
  role_id?: number;
  counterparty_id?: number;
  is_staff?: boolean;
};
export type UserUpdateWrite = {
  password?: string;
  first_name?: string;
  last_name?: string;
  new_username?: string;
  surname?: string;
  available_park_ids?: number[];
  role_id?: number;
  counterparty_id?: number;
  is_staff?: boolean;
};
export type PatchedUserUpdate = {
  first_name?: string;
  last_name?: string;
  surname?: string;
  available_park_ids?: number[];
  role_id?: number;
  counterparty_id?: number;
  is_staff?: boolean;
};
export type PatchedUserUpdateWrite = {
  password?: string;
  first_name?: string;
  last_name?: string;
  new_username?: string;
  surname?: string;
  available_park_ids?: number[];
  role_id?: number;
  counterparty_id?: number;
  is_staff?: boolean;
};
export type UserCreate = {
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  first_name: string;
  last_name: string;
  surname?: string;
  available_park_ids: number[];
  role_id: number;
  counterparty_id?: number;
  is_staff?: boolean;
};
export type UserCreateWrite = {
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  surname?: string;
  available_park_ids: number[];
  role_id: number;
  counterparty_id?: number;
  is_staff?: boolean;
};
export type UserBaseInfo = {
  first_name?: string;
  last_name?: string;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  surname?: string;
  /** Designates whether the user can log into this admin site. */
  is_staff?: boolean;
  counterparty_id?: number | null;
  available_park_ids?: number[] | null;
};
export type UserBaseInfoRead = {
  first_name?: string;
  last_name?: string;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  surname?: string;
  role_id: number | null;
  /** Designates whether the user can log into this admin site. */
  is_staff?: boolean;
  counterparty_id?: number | null;
  available_park_ids?: number[] | null;
};
export type PatchedUserBaseInfo = {
  first_name?: string;
  last_name?: string;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username?: string;
  surname?: string;
  /** Designates whether the user can log into this admin site. */
  is_staff?: boolean;
  counterparty_id?: number | null;
  available_park_ids?: number[] | null;
};
export type PatchedUserBaseInfoRead = {
  first_name?: string;
  last_name?: string;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username?: string;
  surname?: string;
  role_id?: number | null;
  /** Designates whether the user can log into this admin site. */
  is_staff?: boolean;
  counterparty_id?: number | null;
  available_park_ids?: number[] | null;
};
export const {
  useApiAuthTokenAuthCreateMutation,
  useApiAuthTokenRefreshCreateMutation,
  useApiAuthTokenVerifyCreateMutation,
  useApiUsersDeleteDestroyMutation,
  useApiUsersUpdatePartialUpdateMutation,
  useApiUsersCreateCreateMutation,
  useApiUsersListListQuery,
  useApiUsersSelfRetrieveQuery,
  useApiUsersSelfPartialUpdateMutation,
} = injectedRtkApi;
