import {
  GetFirmwaresApiFirmwaresGetApiResponse,
  TrackerCommandWithParams,
  TrackerOutput,
} from '@shared/api/services/tracker/api';
import { RowSelectionState } from '@tanstack/react-table';
import { FC, useEffect, useState } from 'react';
import {
  AdditionalInformation,
  FieldErrorContainer,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';
import { StyledCheckbox } from '@shared/ui/custom-checkbox/styled';
import {
  FormLayout,
  CustomFormColumn,
  TrackersList,
  TrackersContainer,
  CustomUiButton,
  FirmwaresList,
  IntevalInputField,
  UpdateFirmwareButton,
} from './styles';
import Radio, { RadioChangeEvent } from 'antd/es/radio';
import { z } from 'zod';
import { UiRadioButton } from '@shared/ui/ui-radio-button';

const positiveInteger = z.coerce.number().int().min(5);

const interval = z.preprocess(
  (x) => (x ? x : undefined),
  positiveInteger.optional()
);

type Props = {
  selectedImeis: string[];
  trackers: TrackerOutput[];
  firmwaresData: GetFirmwaresApiFirmwaresGetApiResponse;
  handleUpdateInformation: (data: TrackerCommandWithParams) => Promise<void>;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
};

export const GroupOperationsForm: FC<Props> = ({
  trackers,
  firmwaresData,
  selectedImeis,
  handleUpdateInformation,
  setRowSelection,
}) => {
  const [selectedFirmware, selectFirmware] = useState<string | null>(null);
  const [intervalValue, setIntervalValue] = useState<string | null>(null);
  const [isValidValue, checkIsValidValue] = useState<boolean>(true);

  useEffect(() => {
    if (intervalValue !== '' && interval.safeParse(intervalValue).success) {
      checkIsValidValue(true);
    } else {
      checkIsValidValue(false);
    }
    return () => setRowSelection({});
  }, [intervalValue]);

  const onChangeSelectedFirmware = (e: RadioChangeEvent) => {
    selectFirmware(e.target.value);
  };

  const trackersListItems = trackers.map(({ imei }) => {
    const isChecked = selectedImeis.includes(imei);
    return (
      <li
        style={{ height: '20px', color: isChecked ? '#09FBA8' : '#F7FAFF' }}
        key={imei}
      >
        <StyledCheckbox checked={isChecked} disabled />
        ID {imei}
      </li>
    );
  });

  const firmwaresListItems = firmwaresData?.map((firmware) => (
    <UiRadioButton
      style={{ width: 'calc(100% - 9px)' }}
      value={firmware}
      label={firmware}
    />
  ));

  const isSubmitIntervallAllowed =
    intervalValue !== null && intervalValue !== '' && isValidValue;

  return (
    <div>
      <FormLayout>
        <CustomFormColumn>
          <TrackersContainer>
            <FormItemLabel>Трекеры</FormItemLabel>
            <AdditionalInformation>
              Выбрано: {selectedImeis.length}
            </AdditionalInformation>
            <TrackersList>{trackersListItems}</TrackersList>
            <CustomUiButton
              variant={'primary'}
              onClick={() => {
                handleUpdateInformation({
                  imeis: selectedImeis,
                  command: 'INFO',
                });
              }}
            >
              Актуализировать информацию
            </CustomUiButton>
            <CustomUiButton
              variant={'primary'}
              onClick={() => {
                handleUpdateInformation({
                  imeis: selectedImeis,
                  command: 'RESET',
                });
              }}
            >
              Перезагрузить
            </CustomUiButton>
          </TrackersContainer>
        </CustomFormColumn>
        <CustomFormColumn>
          <FormItemLabel>Выберите прошивку</FormItemLabel>
          <FirmwaresList>
            <Radio.Group
              onChange={onChangeSelectedFirmware}
              value={selectedFirmware}
            >
              {firmwaresListItems}
            </Radio.Group>
          </FirmwaresList>
          <UpdateFirmwareButton
            disabled={selectedImeis.length === 0 || selectedFirmware === null}
            variant={selectedFirmware ? 'primary' : 'outline'}
            onClick={() => {
              if (selectedImeis.length === 0 || selectedFirmware === null) {
                return;
              }
              handleUpdateInformation({
                imeis: selectedImeis,
                command: 'UPDATE_FIRMWARE',
                firmware: selectedFirmware,
              });
            }}
          >
            Обновить прошивку*
          </UpdateFirmwareButton>
          <div style={{ height: '30px' }}>
            <AdditionalInformation>
              *По выбранным трекерам
            </AdditionalInformation>
          </div>
        </CustomFormColumn>
        <CustomFormColumn>
          <FormItemLabel>Интервал отправки сообщения (сек)</FormItemLabel>
          <IntevalInputField
            isErrorValue={!isValidValue}
            onChange={(e) => {
              setIntervalValue(e.target.value);
            }}
            value={intervalValue ?? ''}
            placeholder="Заполните поле"
          />
          {!isValidValue && (
            <FieldErrorContainer>
              {<p>Значение должно быть целым числом большим или равным 5</p>}
            </FieldErrorContainer>
          )}
          <CustomUiButton
            disabled={!isSubmitIntervallAllowed}
            variant={isSubmitIntervallAllowed ? 'primary' : 'outline'}
            onClick={() => {
              if (!isSubmitIntervallAllowed) return;
              handleUpdateInformation({
                imeis: selectedImeis,
                command: 'UPDATE_INTERVAL',
                interval: Number(intervalValue),
              });
            }}
          >
            Применить
          </CustomUiButton>
        </CustomFormColumn>
      </FormLayout>
    </div>
  );
};
