import { FC } from 'react';
import { useData } from '../hooks/use-data';
import { TableOfTelemetryType } from '../consts/types';

import { VehiclesTelemetryTableInstanse } from './vehicles-telemetry-table';
import { BatteriesTelemetryTableInstanse } from './batteries-telemetry-table';
import { countPageSize } from '@shared/ui/table/lib/count-page-size';

type Props = {
  tableType: TableOfTelemetryType;
};

export const TelemetryConnectedTable: FC<Props> = ({ tableType }) => {
  const {
    vehiclesTelemetryTableData,
    batteriesTelemetryTableData,
    error,
    isSuccess,
    isLoading,
  } = useData();

  if (error) {
    return (
      <>
        <div>Ошибка: не удалось загрузить телеметрию</div>
        <br />
        <div>{JSON.stringify(error)}</div>
        <br />
      </>
    );
  }
  return (
    <>
      {tableType === 'vehicle' && (
        <VehiclesTelemetryTableInstanse
          vehiclesData={vehiclesTelemetryTableData}
          count={vehiclesTelemetryTableData.length}
          loading={isLoading}
          defaultPageSize={countPageSize()}
        />
      )}
      {tableType === 'battery' && (
        <BatteriesTelemetryTableInstanse
          batteriesData={batteriesTelemetryTableData}
          count={batteriesTelemetryTableData.length}
          loading={isLoading}
          defaultPageSize={countPageSize()}
        />
      )}
    </>
  );
};
