import { useGetCounterpartyInfoApiCounterpartiesGetQuery } from '@shared/api/services/common/api';
import {
  useLazyGetCounterpartiesQuery,
  useGetGroupsQuery,
  useLazyGetCounterpartyQuery,
} from '@shared/api/services/common/enhanced';
import {
  useGerUsersListQuery,
  useGetUsersInfoQuery,
} from '@shared/api/services/user/enhanced';
import { useEffect } from 'react';

export const useData = () => {
  const {
    data: profile,
    isLoading: isProfileLoading,
    isSuccess: isProfileSuccess,
    error: profileError,
  } = useGetUsersInfoQuery();

  const {
    data: users,
    isLoading: isUsersLoading,
    error: usersError,
    isSuccess: isUsersSuccess,
  } = useGerUsersListQuery();

  const [
    getCounterparties,
    {
      data: counterparties,
      isLoading: isCounterpartiesLoading,
      error: counterpartiesError,
      isSuccess: isCounterpartiesSuccess,
    },
  ] = useLazyGetCounterpartiesQuery();

  const [
    getCounterparty,
    {
      data: counterparty,
      isLoading: isCounterpartyLoading,
      error: counterpartyError,
      isSuccess: isCounterpartySuccess,
    },
  ] = useLazyGetCounterpartyQuery();

  const {
    data: parks,
    isLoading: isParksLoading,
    error: parksError,
    isSuccess: isParksSuccess,
  } = useGetGroupsQuery();

  useEffect(() => {
    if (profile?.is_staff === undefined || profile?.is_staff === null) return;

    if (profile.is_staff) {
      getCounterparties();
    } else {
      getCounterparty();
    }
  }, [profile?.is_staff]);

  const error = [
    usersError,
    counterpartiesError,
    parksError,
    profileError,
    counterpartyError,
  ].find((err) => err !== undefined);

  const isLoading =
    isUsersLoading ||
    isCounterpartiesLoading ||
    isParksLoading ||
    isProfileLoading ||
    isCounterpartyLoading;

  const isSuccess =
    isUsersSuccess &&
    isCounterpartiesSuccess &&
    isParksSuccess &&
    isProfileSuccess &&
    isCounterpartySuccess;

  return {
    users,
    counterparties,
    counterparty,
    parks,
    profile,
    isLoading,
    isSuccess,
    error,
  };
};
