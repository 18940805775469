import { createSlice } from '@reduxjs/toolkit';

type State = {
  typeId?: number;
  statusEvIds: number[];
  modelsIds: number[];
  manufactureIds: number[];
  parkId?: number;
  groupIds: number[];
  socFrom?: number;
  socTo?: number;
  sohFrom?: number;
  sohTo?: number;
  mileageFrom?: number;
  mileageTo?: number;
  powerReserveFrom?: number;
  powerReserveTo?: number;
};

const initialState: State = {
  statusEvIds: [],
  modelsIds: [],
  manufactureIds: [],
  groupIds: [],
  typeId: undefined,
};

const slice = createSlice({
  name: 'vehicleFilters',
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const { setFilters } = slice.actions;

export const selectVehicleFilters = (state: RootState) => state.vehicleFilters;

export const selectHasFilters = (state: RootState) => {
  const {
    groupIds,
    parkId,
    manufactureIds,
    modelsIds,
    statusEvIds,
    typeId,
    mileageFrom,
    mileageTo,
    powerReserveFrom,
    powerReserveTo,
    socFrom,
    socTo,
    sohFrom,
    sohTo,
  } = state.vehicleFilters;

  if (Boolean(typeId)) return true;

  if (
    [
      mileageFrom,
      mileageTo,
      powerReserveFrom,
      powerReserveTo,
      socFrom,
      socTo,
      sohFrom,
      sohTo,
    ].some((el) => el !== undefined)
  ) {
    return true;
  }

  return [groupIds, manufactureIds, modelsIds, statusEvIds].some((el) => {
    if (el === undefined) {
      return false;
    }
    return el.length > 0;
  });
};

export default slice.reducer;
