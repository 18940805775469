import { Cell, flexRender, Header, Row, Table } from '@tanstack/react-table';
import { Spin } from 'antd';
import { SortingButtons } from '../../sorting-buttons';

import {
  ControlsContainer,
  // HeaderCellContainer,
  // HeaderCellContent,
  // HeaderCellText,
  StyledFixedTable,
  TablaLayoutContainer,
  TableContainer,
  ThContainer,
  ThTitle,
} from './styles';

// const { Text } = Typography;

type Props = {
  table: Table<any>;
  loading: boolean;
  handleRowDoubleClick?: (row: Row<any>) => void;
  // handleSortChange: (
  //   header: Header<any, unknown>
  // ) => ((event: unknown) => void) | undefined;
  // getTdClassName: (cell: Cell<any, unknown>) => string;
  tableControls: JSX.Element;
};

export function TableLayout({
  table,
  loading,
  // handleSortChange,
  handleRowDoubleClick,
  //getTdClassName,
  tableControls,
}: Props) {
  return (
    <TablaLayoutContainer>
      <TableContainer>
        <Spin spinning={loading}>
          <StyledFixedTable>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    // const filterElement =
                    //   header.column.columnDef.meta?.filterElement;

                    return (
                      <th
                        {...{
                          key: header.id,
                          style: {
                            width: header.getSize(),
                          },
                          className: `${
                            header.column.getIsSorted() !== false
                              ? 'sorted'
                              : ''
                          }`,
                        }}
                      >
                        <ThContainer>
                          <ThTitle>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </ThTitle>
                          {header.id !== 'settings' &&
                          header.column.getCanSort() ? (
                            <SortingButtons
                              sortingType={header.column.getIsSorted()}
                              headerId={
                                header.column.columnDef.meta?.sortingName ??
                                header.id
                              }
                            />
                          ) : null}
                        </ThContainer>
                        {/* <HeaderCellContainer>
                        <HeaderCellContent
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer'
                              : '',
                            onClick:
                              header.id !== 'settings' &&
                              header.column.getCanSort()
                                ? handleSortChange(header)
                                : undefined,
                          }}
                        >
                          <HeaderCellText className="header">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </HeaderCellText>
                          {header.id !== 'settings' &&
                          header.column.getCanSort() ? (
                            <SortingButtons
                              sortingType={header.column.getIsSorted()}
                            />
                          ) : null}
                        </HeaderCellContent>
                        {filterElement || null}
                      </HeaderCellContainer> */}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  {...(handleRowDoubleClick
                    ? {
                        onDoubleClick: () => handleRowDoubleClick(row),
                        className: `clickable${
                          row.getIsSelected() ? ' selected' : ''
                        }`,
                      }
                    : {})}
                >
                  {row.getVisibleCells().map((cell) => {
                    const cellContent = flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    );

                    return (
                      <td
                        key={cell.id}
                        // className={getTdClassName(cell)}
                        style={{
                          width: cell.column.getSize(),
                        }}
                      >
                        <div
                          style={{ width: cell.column.getSize() }}
                          ellipsis={{
                            tooltip: cellContent,
                          }}
                        >
                          {cellContent}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </StyledFixedTable>
        </Spin>
      </TableContainer>
      <ControlsContainer>{tableControls}</ControlsContainer>
    </TablaLayoutContainer>
  );
}
