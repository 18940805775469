import { StatusTracker } from '@shared/api/services/tracker/api';
import { FormSchema, FormSchemaType } from '../../consts/schema';
import { useData } from '../../hooks/use-data';
import { useForm, Controller, type FieldValues } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppSelector } from '@app/hooks';
import {
  selectTrackersFilters,
  setFilters,
} from '@shared/redux/slices/tracker/filters';
import { resetPageByFiltering } from '@shared/utils/table/reset-page-by-filtering';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormButtonsContainer, FormLayout } from './styles';
import { UiButton } from '@shared/ui/ui-button';
import {
  FieldErrorContainer,
  FormColumn,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';
import { CustomSelect } from '@shared/ui/custom-select-new';
import { CustomSelectMultiple } from '@shared/ui/custom-select-new/components/multiple-select';

const trackerStatusOptions: Array<{ label: string; value: StatusTracker }> = [
  { label: 'Online', value: 'ONLINE' },
  { label: 'Offline', value: 'OFFLINE' },
];

type Props = {
  closeModal: () => void;
};

export function FiltersForm({ closeModal }: Props) {
  const filters = useAppSelector(selectTrackersFilters);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const { isLoading, error, trackersModels, firmwaresData } = useData();

  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: filters,
  });

  const statuses = watch('statuses');
  const modelIds = watch('modelIds');
  const firmware = watch('firmware');

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <>
        <div>Ошибка: не удалось загрузить фильтры</div>
        <br />
        <div>{JSON.stringify(error)}</div>
        <br />
      </>
    );
  }

  const onSubmit = async (data: FieldValues) => {
    resetPageByFiltering(searchParams, setSearchParams);
    dispatch(setFilters(data));
    closeModal();
  };

  const handleReset = () => {
    setValue('modelIds', []);
    setValue('statuses', []);
    setValue('firmware', undefined);
  };

  const modelsOptions = trackersModels!;
  const firmwaresOptions = firmwaresData!;
  const trackerStatusOptions: Array<{ label: string; value: StatusTracker }> = [
    { label: 'Online', value: 'ONLINE' },
    { label: 'Offline', value: 'OFFLINE' },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="password">
      <FormLayout>
        <FormColumn>
          <FormItemContainer>
            <FormItemLabel>Модель</FormItemLabel>
            <Controller
              name="modelIds"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <CustomSelectMultiple
                    {...field}
                    value={modelIds}
                    options={modelsOptions.map((el) => ({
                      value: el.id,
                      label: el.name,
                    }))}
                    allowClear
                    onClear={() => setValue('modelIds', [])}
                  />
                );
              }}
            />
            <FieldErrorContainer>
              {errors?.modelIds && <p>{errors.modelIds.message}</p>}
            </FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>Статус трекера</FormItemLabel>
            <Controller
              name="statuses"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <CustomSelectMultiple
                    {...field}
                    value={statuses}
                    options={trackerStatusOptions.map(({ label, value }) => ({
                      value,
                      label,
                    }))}
                    allowClear
                    onClear={() => setValue('statuses', [])}
                  />
                );
              }}
            />
            <FieldErrorContainer>
              {errors?.statuses && <p>{errors.statuses.message}</p>}
            </FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>Прошивка</FormItemLabel>
            <Controller
              name="firmware"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <CustomSelect
                    {...field}
                    value={firmware}
                    options={firmwaresOptions.map((el) => ({
                      value: el,
                      label: el,
                    }))}
                    allowClear
                    onClear={() => setValue('firmware', undefined)}
                  />
                );
              }}
            />
            <FieldErrorContainer>
              {errors?.firmware && <p>{errors.firmware.message}</p>}
            </FieldErrorContainer>
          </FormItemContainer>
        </FormColumn>
      </FormLayout>

      <FormButtonsContainer>
        <UiButton variant="outline" onClick={handleReset} type="button">
          Сбросить
        </UiButton>
        <UiButton variant="primary" type="submit">
          Применить
        </UiButton>
      </FormButtonsContainer>
    </form>
  );
}
