import { useParams } from 'react-router-dom';
import { HeaderLayout } from '../layout';
import { ContentWrapper, Outer, TransportTitle } from './styles';
import { useParkVehicleData } from '@entities/vehicle/hooks/use-park-vehicle-data';

type Props = {
  showVehicleInfo: boolean;
};

export function ElectrobusPageHeader({ showVehicleInfo }: Props) {
  const { eParkId, convoyId, eBusId } = useParams();
  const { parks, electricbuses } = useParkVehicleData({});

  const park = parks?.find(({ id }) => Number(id) === Number(eParkId));
  const convoy = park?.groups?.find(({ id }) => String(id) === convoyId);
  const ebus = electricbuses?.find(({ id }) => id === eBusId);

  const title = `${park?.name ?? ''}, ${convoy?.name ?? ''}, ${
    ebus?.grn ?? ''
  }`;

  const content = (
    <ContentWrapper>
      <TransportTitle>{title}</TransportTitle>
    </ContentWrapper>
  );

  return (
    <Outer showVehicleInfo={showVehicleInfo}>
      <HeaderLayout content={content} isStadardHeader={false} />
    </Outer>
  );
}
