import { useMatch, useResolvedPath, useLocation } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { Spin } from 'antd';

import { TRANSPORT } from '@shared/consts/routes';
import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';
import { ArrowDownIcon } from '@shared/icons/arrow-down';

import { VehiclesTree } from '@entities/vehicle/ui/tree/tree';
import { EBusSiderNavMenuItemLayout } from '../e-bus-item-layout';
import { SiderNavMenuItemLayout } from '../item-layout-new';
import { StyledCollapse } from '../styles';

import { ElectrobusLeaf } from '@entities/vehicle/ui/tree/electrobus-leaf';

import {
  StyledParkCollapses,
  StyledConvoyCollapses,
  LeafsContainer,
} from './styles';
import { useParkVehicleData } from '@entities/vehicle/hooks/use-park-vehicle-data';

type Props = {
  eBusParkId: string;
  convoyId: string;
  vehicle: any;
};

const VehicleNavComponent: FC<Props> = ({ eBusParkId, convoyId, vehicle }) => {
  return (
    <EBusSiderNavMenuItemLayout
      path={`${TRANSPORT}/${eBusParkId}/${convoyId}/${vehicle.id}`}
      content={<ElectrobusLeaf vehicle={vehicle} key={vehicle.id} />}
    />
  );
};

export function VehiclesMenu() {
  const [activeKey, setActiveKey] = useState<string | string[]>([]);

  const resolvedPath = useResolvedPath(TRANSPORT);

  const match = useMatch({
    path: resolvedPath.pathname,
    end: TRANSPORT.length === 0,
  });

  const { parks, electricbuses, isLoading, isSuccess } = useParkVehicleData({});

  useEffect(() => {
    if (!match) {
      setActiveKey([]);
    }
  }, [match]);

  const className = match ? 'active' : '';

  const hasNodata = isLoading || !isSuccess;

  return (
    <Spin spinning={isLoading}>
      <SiderNavMenuItemLayout
        path={TRANSPORT}
        content={
          <StyledCollapse
            activeKey={activeKey}
            ghost
            rootClassName={hasNodata ? 'no-content' : ''}
            collapsible={'header'}
            onChange={(key) => {
              setActiveKey(key);
            }}
            items={[
              {
                //                key: `transport`,
                id: 'vehicles-menu',
                label: 'Транспорт',
                children: (
                  <div>
                    {parks.map(({ id, name, groups }) => {
                      const eBusParkId = String(id);
                      const eBusName = name;
                      const convoys = groups ?? [];

                      return (
                        <EBusSiderNavMenuItemLayout
                          key={eBusParkId}
                          path={`${TRANSPORT}/${eBusParkId}`}
                          content={
                            <StyledParkCollapses
                              //                              activeKey={activeKey}
                              //                              className={'active'}
                              collapsible="icon"
                              ghost
                              defaultActiveKey={[]}
                              items={[
                                {
                                  key: `${eBusParkId}`,
                                  id: `${eBusParkId}`,
                                  label: eBusName,
                                  children: (
                                    <div>
                                      {convoys.map(({ id, name }) => {
                                        const convoyId = String(id);
                                        const convoyName = name;
                                        const vehiclesOfConvoy =
                                          electricbuses.filter(
                                            ({ group_id }) => group_id === id
                                          ) ?? [];

                                        return (
                                          <EBusSiderNavMenuItemLayout
                                            key={convoyId}
                                            path={`${TRANSPORT}/${eBusParkId}/${convoyId}`}
                                            content={
                                              <StyledConvoyCollapses
                                                //                                                activeKey={activeKey}
                                                collapsible="icon"
                                                ghost
                                                defaultActiveKey={[]}
                                                expandIconPosition="end"
                                                expandIcon={(_panelProps) => {
                                                  return <ArrowDownIcon />;
                                                }}
                                                items={[
                                                  {
                                                    key: `${convoyId}`,
                                                    id: convoyId,
                                                    label: convoyName,
                                                    children: (
                                                      <LeafsContainer>
                                                        {vehiclesOfConvoy.map(
                                                          (vehicle) => {
                                                            return (
                                                              <VehicleNavComponent
                                                                key={vehicle.id}
                                                                eBusParkId={
                                                                  eBusParkId
                                                                }
                                                                convoyId={
                                                                  convoyId
                                                                }
                                                                vehicle={
                                                                  vehicle
                                                                }
                                                              />
                                                            );
                                                          }
                                                        )}
                                                      </LeafsContainer>
                                                    ),
                                                  },
                                                ]}
                                              />
                                            }
                                          />
                                        );
                                      }) ?? []}
                                    </div>
                                  ),
                                },
                              ]}
                              expandIconPosition="end"
                              expandIcon={(_panelProps) => {
                                return <ArrowDownIcon />;
                              }}
                            />
                          }
                        />
                      );
                    })}
                  </div>
                ),
              },
            ]}
            expandIconPosition="end"
            expandIcon={(_panelProps) => {
              return <ArrowDownIcon />;
            }}
            className={className}
          />
        }
      />
    </Spin>
  );
}
