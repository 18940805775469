import { Radio } from 'antd';
import styled from 'styled-components';

export const CustomRadioButton = styled(Radio)`
  color: #f7faff;

  &.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #09fba8;
    background-color: #09fba8;
  }
`;
