import { Radio } from 'antd';
import { FC } from 'react';
import { CustomRadioButton } from './styles';

type Props = {
  label: string;
  value?: any;
  style?: React.CSSProperties;
};

export const UiRadioButton: FC<Props> = ({ label, value, style }) => {
  return (
    <CustomRadioButton style={style} value={value}>
      {label}
    </CustomRadioButton>
  );
};
