import { useEffect } from 'react';
import {
  useLazyGetCounterpartiesQuery,
  useLazyGetCounterpartyQuery,
} from '@shared/api/services/common/enhanced';
import { useGetUsersInfoQuery } from '@shared/api/services/user/enhanced';

export const useCounterparties = () => {
  const {
    data: profile,
    isLoading: isProfileLoading,
    isSuccess: isProfileSuccess,
    error: profileError,
  } = useGetUsersInfoQuery();

  const [
    getCounterparties,
    {
      data: counterparties,
      isLoading: isCounterpartiesLoading,
      error: counterpartiesError,
      isSuccess: isCounterpartiesSuccess,
    },
  ] = useLazyGetCounterpartiesQuery();

  const [
    getCounterparty,
    {
      data: counterparty,
      isLoading: isCounterpartyLoading,
      error: counterpartyError,
      isSuccess: isCounterpartySuccess,
    },
  ] = useLazyGetCounterpartyQuery();

  useEffect(() => {
    if (profile?.is_staff === undefined || profile?.is_staff === null) return;

    if (profile.is_staff) {
      getCounterparties();
    } else {
      getCounterparty();
    }
  }, [profile?.is_staff]);

  const isLoading =
    isCounterpartiesLoading || isCounterpartyLoading || isProfileLoading;

  const error = [profileError, counterpartiesError, counterpartyError].find(
    (err) => err !== undefined
  );

  const isSuccess =
    isProfileSuccess && isCounterpartiesSuccess && isCounterpartySuccess;

  return {
    counterparty,
    counterparties,
    isCounterpartiesDataLoading: isLoading,
    counterpartiesDataError: error,
    isCounterpartiesDataSuccess: isSuccess,
    isStaff: profile?.is_staff,
  };
};
