import NiceModal from '@ebay/nice-modal-react';
import { AddIcon } from '@shared/icons/add';
import { ButtonLabel, StyledButton } from './styles';
import { AddUserModal } from '../modal';

export const AddUserButton = () => {
  const showAddModal = () => {
    NiceModal.show(AddUserModal, {});
  };

  return (
    <StyledButton onClick={showAddModal}>
      <ButtonLabel>Добавить пользователя</ButtonLabel>
      <AddIcon />
    </StyledButton>
  );
};
