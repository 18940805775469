import { GroupOutput, ParkOutput } from '@shared/api/services/common/api';

export const getCounterpartyIdByParkId = (
  groupId: number,
  parks: ParkOutput[]
): number | undefined => {
  const park = parks.find((park) =>
    park.groups?.find((el) => el.id === groupId)
  );

  if (park) {
    return park.counterparty_id;
  }
};
