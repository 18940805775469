import { GetParksApiParksGetApiResponse } from '@shared/api/services/common/api';
import { VehicleTableType } from '../model/vehicle-table-type';

export const preprocessGroupIdsArg = (
  tableType: VehicleTableType,
  eParkId?: string,
  convoyId?: string,
  parks?: GetParksApiParksGetApiResponse
) => {
  if (parks === undefined || tableType === 'TRANSPORT') return [];

  if (tableType === 'PARK') {
    const groupIdsInParticularPark =
      parks
        .find(({ id }) => String(id) === eParkId)
        ?.groups?.map(({ id }) => id) ?? [];

    return groupIdsInParticularPark.length > 0 ? groupIdsInParticularPark : [];
  }

  if (tableType === 'CONVOY') {
    return convoyId ? [Number(convoyId)] : [];
  }

  return [];
};
