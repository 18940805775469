import NiceModal from '@ebay/nice-modal-react';
import { DeleteButton } from '@shared/ui/delete-button';
import { DeleteUserModal } from '../modal';
import { UserBaseInfoRead } from '@shared/api/services/user/api';

type Props = {
  user: UserBaseInfoRead;
  closeParentModal: () => void;
};

export function DeleteUserButton({ user, closeParentModal }: Props) {
  const showModal = () => {
    NiceModal.show(DeleteUserModal, { user, closeParentModal });
  };

  return <DeleteButton showIcon toShowModal={showModal} />;
}
