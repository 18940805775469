import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';
import { useLazyElectricbusesQuery } from '@shared/api/services/electricbus/enhanced';
import { useEffect } from 'react';
import { GroupOutput } from '@shared/api/services/common/api';

type Props = {
  groupIds?: string;
  electricbusesPolling?: number;
};

export const useParkVehicleData = ({
  electricbusesPolling,
  groupIds,
}: Props) => {
  const {
    data: parksData = [],
    isLoading: isParksLoading,
    error: parksError,
    isSuccess: isParksSuccess,
  } = useGetGroupsQuery(undefined);

  const [
    getElectrobuses,
    {
      data: electricbuses,
      isLoading: isElectricbusesLoading,
      error: electricbusesError,
      isSuccess: isElectricbusesSuccess,
      isFetching: isElectricbusesFetching,
    },
  ] = useLazyElectricbusesQuery({ pollingInterval: electricbusesPolling });

  useEffect(() => {
    if (parksData.length !== 0) {
      const defaultGroupIds = parksData
        .reduce((acc, curr) => {
          return [...acc, ...curr.groups];
        }, [] as GroupOutput[])
        .map(({ id }) => id)
        .join(',');

      const payload = groupIds ?? defaultGroupIds;

      getElectrobuses(payload);
    }
  }, [parksData, groupIds]);

  const isLoading = isParksLoading || isElectricbusesLoading;
  const isSuccess = isElectricbusesSuccess && isParksSuccess;

  const error = [electricbusesError, parksError].find(
    (err) => err !== undefined
  );

  const result = {
    parks: parksData ?? [],
    electricbuses: electricbuses ?? [],
    isLoading,
    isSuccess,
    error,
  };

  return result;
};
