import { z } from 'zod';

import { numberInString } from '@shared/consts/schemas';

export const FormSchema = z.object({
  grn: z
    .string({ required_error: 'Поле обязательно' })
    .trim()
    .min(1, { message: 'Поле должно содержать как минимум один символ' }),
  garage_number: z
    .string({ required_error: 'Поле обязательно' })
    .trim()
    .min(1, { message: 'Поле должно содержать как минимум один символ' }),
  type_id: z.number({ required_error: 'Поле обязательно' }),
  model_id: z.number({ required_error: 'Поле обязательно' }),
  group_id: z.number({ required_error: 'Поле обязательно' }),
  manufacture_id: z.number({ required_error: 'Поле обязательно' }),
  status_id: z.number({ required_error: 'Поле обязательно' }),
  park_id: z.number({ required_error: 'Поле обязательно' }),
});

export type FormSchemaType = z.infer<typeof FormSchema>;
