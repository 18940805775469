import styled from 'styled-components';

export const FormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  & > input {
    width: 100%;
  }
`;

export const FormItemLabel = styled.label`
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  //gap: 32px;
`;

export const FormButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

export const FieldErrorContainer = styled.div`
  height: 18px;
  color: #fe4d4d;
`;

export const AdditionalInformation = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #a3aec4;
`;
