import { UsersHeader } from '@widgets/header/ui/users';
import { ConnectedUsersTable } from '@features/users/table/ui/connected-table';
import { StandardPageLayout } from '../styles';
import { useGetUsersInfoQuery } from '@shared/api/services/user/enhanced';

export function UsersPage() {
  const { data, isLoading, isSuccess, error } = useGetUsersInfoQuery();

  const isAdmin = data?.role_id === 3;

  return (
    <StandardPageLayout>
      {isAdmin ? (
        <>
          <UsersHeader />
          <ConnectedUsersTable />
        </>
      ) : (
        <>
          Информация доступна только для пользователей с правами администратора
        </>
      )}
    </StandardPageLayout>
  );
}
