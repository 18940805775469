import NiceModal from '@ebay/nice-modal-react';

import { AddIcon } from '@shared/icons/add';

import { AddTrackerModal } from '@features/trackers/add/ui/modal';
import { ButtonLabel, StyledButton } from './styles';
import { useRole } from '@entities/user/lib/hooks/use-role';

export function AddTrackerButton() {
  const { isDispatcher, isAdmin, isLoading } = useRole();

  const showButton = isDispatcher || isAdmin;

  if (isLoading) {
    return null;
  }

  const showAddModal = () => {
    NiceModal.show(AddTrackerModal, {});
  };

  if (showButton) {
    return (
      <StyledButton
        onClick={() => {
          showAddModal();
        }}
      >
        <ButtonLabel>Добавить трекер</ButtonLabel>
        <AddIcon />
      </StyledButton>
    );
  }

  return null;
}
